import intl from '../components'

const messages = {
  [intl.advancedFilters('above')]: `Over`,
  [intl.advancedFilters('below')]: `Under`,
  [intl.advancedSearch('add-filters')]: 'Legg til filter',
  [intl.advancedFilters('company-type')]: `Selskapsform`,
  [intl.advancedFilters(
    'count'
  )]: `{count, plural, =0 {Ingen selskaper funnet} ett {{count} selskap funnet} flere {{count} selskaper funnet}}`,
  [intl.advancedFilters('employee-count')]: `Antall ansatte`,
  [intl.advancedFilters('equity')]: `Egenkapital`,
  [intl.advancedFilters('financial-data')]: `Finansielle data`,
  [intl.advancedFilters('hide-ad-protected')]: `Skjul reklamebeskyttede`,
  [intl.advancedFilters('industry-group')]: `Industri`,
  [intl.advancedFilters('industry-specific')]: `Spesifikk industri`,
  [intl.advancedFilters('municipality')]: `Kommune`,
  [intl.advancedFilters('region')]: `Region`,
  [intl.advancedFilters('reset-search')]: `Nullstill søk`,
  [intl.advancedFilters('risika-score')]: `Risika Score`,
  [intl.advancedFilters('search-company-type')]: `Søk etter selskapsform`,
  [intl.advancedFilters('search-industry-group')]: `Søk etter industri`,
  [intl.advancedFilters('search-industry-specific')]: `Søk etter spesifikk industri`,
  [intl.advancedFilters('search-municipality')]: `Søk etter kommune`,
  [intl.advancedFilters('search-region')]: `Søk etter region`,
  [intl.advancedFilters('select-columns')]: `Velg kolonner som skal eksporteres`,
  [intl.advancedFilters('show-inactive')]: `Vis inaktive`,
  [intl.advancedFilters('title')]: `Filtrering`,
  [intl.advancedFilters(
    'illegal-character'
  )]: `Invalid {count, plural, one {character} other {characters}} "{char}"! Tillatte tegn er "0-9", "-" og ","`,
  [intl.advancedFilters(
    'advanced-zipcodes-helper-text'
  )]: `Tillatt format: 1000, 2000-2500`,
  [intl.advancedFilters('values-or-ranges')]: `Skriv enkeltverdier eller områder`,
  [intl.creditPolicyNew('fraud_indicators')]: `Svindelindikatorer`,
  [intl.creditPolicyNew('fraud_indicators')]: `Svindelindikatorer`,
  [intl.creditPolicyNew('people_and_company_search')]: `Søk etter personer og selskaper`,
  [intl.creditPolicyNew('financial_indicators')]: `Finansielle indikatorer`,
  [intl.creditPolicyNew('payment_history')]: `Betalingshistorikk`,
  [intl.creditPolicyNew('advanced')]: `Avansert`,
  [intl.creditPolicyNew('outcome')]: `Resultat`,
  [intl.creditPolicyNew('company_type')]: `Selskapsform`,
  [intl.creditPolicyNew('local_id_in_monitoring_list')]: `Selskapsliste`,
  [intl.creditPolicyNew(
    'local_id_in_monitoring_list-node-text'
  )]: `Overvåkingsliste {listId}`,
  [intl.creditPolicyNew('new_monitoring_list')]: `Lag en liste`,
  [intl.creditPolicyNew('new-monitoring-list-name')]: `Navn`,
  [intl.creditPolicyNew('new-monitoring-list-add')]: `Legg til firmaer i listen din`,
  [intl.creditPolicyNew('new-monitoring-list-label')]: `Navn på listen`,
  [intl.creditPolicyNew('industry_code')]: `Bransje`,
  [intl.creditPolicyNew('number_of_employees')]: `Antall ansatte`,
  [intl.creditPolicyNew('industry_type')]: `Bransje`,
  [intl.creditPolicyNew('employees_number')]: `Antal ansatte`,
  [intl.creditPolicyNew('years_of_existence')]: `År i eksistens/Firmaets alder`,
  [intl.creditPolicyNew('ownership_structure')]: `Eierstruktur`,
  [intl.creditPolicyNew(
    'policy-card-last-updated'
  )]: `av {ownerId} sist oppdatert den: {date}`,
  [intl.creditPolicyNew('risika_score')]: `Risika score`,
  [intl.creditPolicyNew('credit_rating')]: ` `,
  [intl.creditPolicyNew('risk_assessment')]: ` `,
  [intl.creditPolicyNew(
    'only-one-active-policy-title'
  )]: `Du kan bare ha én aktiv policy per land`,
  [intl.creditPolicyNew('audit')]: `Revisjon`,
  [intl.creditPolicyNew('unsaved-changes')]: 'Du har ulagrede endringer',
  [intl.creditPolicyNew('discard-changes')]: 'Forkast endringer',
  [intl.creditPolicyNew('cancel')]: 'Avbryt',
  [intl.creditPolicyNew('undo')]: 'Angre',
  [intl.creditPolicyNew('connected_bankruptcies')]: `Forbundne konkurser`,
  [intl.advancedSearch('add-filters')]: 'Legg til filter',
  [intl.advancedSearch('all-filters')]: 'Alle filtre',
  [intl.advancedSearch('apply-filters')]: 'Legg til filter',
  [intl.advancedSearch('assets')]: 'Eiendeler',
  [intl.advancedSearch('auditor')]: 'Revisor',
  [intl.advancedSearch('between')]: 'Mellom',
  [intl.advancedSearch('tree-industry')]: 'Industry',
  [intl.advancedSearch('cash')]: 'Kontanter',
  [intl.advancedSearch('company-info')]: 'Selskapsinformasjon',
  [intl.advancedSearch('company_status')]: 'Selskapsstatus',
  [intl.advancedSearch('company_type')]: 'Selskapsform',
  [intl.advancedSearch('credit')]: 'Kreditt',
  [intl.advancedSearch('current_financial_assets')]: 'Finansielle omløpsmidler',
  [intl.advancedSearch('date_of_incorporation')]: 'Stiftelsesdato',
  [intl.advancedSearch('employees')]: 'Antall ansatte',
  [intl.advancedSearch('employees_number')]: 'Antall ansatte',
  [intl.advancedSearch('enter-percentage')]: 'Angi verdi i prosent (%)',
  [intl.advancedSearch('enter-value')]: 'Angi en verdi',
  [intl.advancedSearch('equity')]: 'Egenkapital',
  [intl.advancedSearch('excludes')]: 'Ekskludert',
  [intl.advancedSearch('financial-data-section')]: 'Finansielle data',
  [intl.advancedSearch('has_phone_number')]: 'Har telefonnummer',
  [intl.advancedSearch('financial-data-select-all')]: 'Velg alle finansielle data',
  [intl.advancedSearch('financial-numbers')]: 'Finansielle tall',
  [intl.advancedSearch('financial-ratios')]: 'Nøkkeltall',
  [intl.advancedSearch('fixed_assets')]: 'Anleggsmidler',
  [intl.advancedSearch('general-data-section')]: 'Generell data',
  [intl.advancedSearch('general-data-select-all')]: 'Velg alle generelle data',
  [intl.advancedSearch('gross_result')]: 'Bruttoresultat',
  [intl.advancedSearch('has_auditor')]: 'Auditor',
  [intl.advancedSearch('has_auditor')]: 'Revisor',
  [intl.advancedSearch('has_email')]: 'Har e-post',
  [intl.advancedSearch('has_webpage')]: 'Har nettside',
  [intl.advancedSearch('hide_advertisement_protected')]: 'Skjul reklame beskyttet',
  [intl.advancedSearch('hide_holding_company')]: 'Skjul holdingselskaper',
  [intl.advancedSearch('includes')]: 'Inkluderer',
  [intl.advancedSearch('industry_group')]: 'Bransje',
  [intl.advancedSearch('liquidity')]: 'Likviditet',
  [intl.advancedSearch('load-filters')]: 'Last inn filtre',
  [intl.advancedSearch('save-filters')]: 'Lagrede filter',
  [intl.advancedSearch('load-filters-title')]: 'Last inn en av dine lagrede filtre',
  [intl.advancedSearch('match')]: 'Match',
  [intl.advancedSearch('municipality')]: 'Kommune',
  [intl.advancedSearch('no-filters')]: 'Ingen filtre valgt',
  [intl.advancedSearch('no-saved-filters')]: 'Du har ingen lagrede filtre',
  [intl.advancedSearch('over')]: 'Over',
  [intl.advancedSearch('placeholder-auditor')]:
    'Skriv inn Organisasjonsnummer eller navn på revisor',
  [intl.advancedSearch('profit_loss')]: 'Overskuddstap',
  [intl.advancedSearch('range-from')]: 'Fra',
  [intl.advancedSearch('range-to')]: 'Til',
  [intl.advancedSearch('region')]: 'Sted',
  [intl.advancedSearch('reset')]: 'Nullstille',
  [intl.advancedSearch('results-time-elapsed')]: 'Resultat / Resultater',
  [intl.advancedSearch('return_on_assets')]: 'Avkastning',
  [intl.advancedSearch('save')]: 'Lagre',
  [intl.advancedSearch('save-filter-label')]: 'Velg et navn for filteret',
  [intl.advancedSearch('save-filter-title')]: 'Lagre filteret for fremtidig bruk',
  [intl.advancedSearch('score')]: 'Risika Score',
  [intl.advancedSearch('search-filter')]: 'Søk etter filter',
  [intl.advancedSearch('select-all')]: 'Velg alle',
  [intl.advancedSearch('share_capital')]: 'Aksjekapital',
  [intl.advancedSearch('show_inactive')]: 'Vis inaktive',
  [intl.advancedSearch('snackbar-delete-filters-failed')]:
    'Noe gikk galt når du prøvde å slette filteret',
  [intl.advancedSearch('snackbar-delete-filters-success')]: 'Filteret ble slettet',
  [intl.advancedSearch('snackbar-saved-filters-failed')]:
    'Noe gikk galt når du prøvde å lagre filteret',
  [intl.advancedSearch('snackbar-saved-filters-success')]: 'Filteret ditt ble lagret',
  [intl.advancedSearch('snackbar-update-filters-failed')]:
    'Noe gikk galt når du prøvde å oppdatere filteret',
  [intl.advancedSearch('snackbar-update-filters-success')]: 'Filteret ble oppdatert',
  [intl.advancedSearch('solidity')]: 'Soliditet',
  [intl.advancedSearch('specific_industry')]: 'Spesifikk industri',
  [intl.advancedSearch('status_valid_from')]: 'Selskapsstatus fra',
  [intl.advancedSearch('total_debt')]: 'Samlet gjeld',
  [intl.advancedSearch('under')]: 'Under',
  [intl.advancedSearch('zipcode')]: 'Postkode',
  [intl.advancedSearch('duration')]: 'Varighet',
  [intl.advancedSearch('period')]: 'Periode',
  [intl.advancedSearch('set-to-today')]: 'Sett til i dag',
  [intl.annualReportInformation('approvalDate')]: `Godkjenningsdato`,
  [intl.annualReportInformation(
    'averageNumberOfEmployees'
  )]: `Gjennomsnittlig antall ansatte`,
  [intl.annualReportInformation(
    'classOfReportingEntity'
  )]: `Klasse av rapporteringsenhet`,
  [intl.annualReportInformation('company_name')]: `Revisor`,
  [intl.annualReportInformation('description')]: `Beskrivelse av revisor`,
  [intl.annualReportInformation('get-intl.auditorInformation')]: `Få revisorinformasjon`,
  [intl.annualReportInformation('ifrs')]: `IFRS`,
  [intl.annualReportInformation('name')]: `Revisors navn`,
  [intl.annualReportInformation('no_information')]: `Ingen informasjon`,
  [intl.annualReportInformation('show_less')]: `Vis mindre`,
  [intl.annualReportInformation('show_more')]: `Vis mer`,
  [intl.annualReportInformation('title')]: `Informasjon om årsrapporten`,
  [intl.annualReportInformation('type_of_assistance')]: `Erklæringstype`,
  [intl.auditorInfo('auditors_report')]: `Revisjonsberetning`,
  [intl.auditorInfo(
    'auditors_report_on_extended_review'
  )]: `Revisors rapport om utvidet gjennomgang`,
  [intl.auditorInfo(
    'auditors_reports_(other_non-assurance_reports)'
  )]: `Revisors rapporter (andre ikke-forsikringsrapporter)`,
  [intl.auditorInfo('company_name')]: `Selskapsnavn`,
  [intl.auditorInfo('name_of_auditor')]: `Navn på Revisor`,
  [intl.auditorInfo('no_auditor_assistance')]: `Ingen revisjonsbistand`,
  [intl.auditorInfo(
    'the_independent_auditors_reports_(review)'
  )]: `Den uavhengige revisors rapporter (gjennomgang)`,
  [intl.auditorInfo('type_of_assistance')]: `Erklæringstype`,
  [intl.auth('account-already-verified')]: `Konoten er allerede verifisert`,
  [intl.auth('account-deactivated')]: `Kontoen din har blitt deaktivert`,
  [intl.auth(
    'account-deactivated-subtitle'
  )]: `Du skal ha mottatt en e-post med instruksjoner om hvordan du aktiverer kontoen din på nytt.`,
  [intl.auth('account-deactivated-title')]: `Konoten er deaktivert`,
  [intl.auth(
    'account-verified-failed'
  )]: `Vi hadde problemer med å verifisere kontoen din`,
  [intl.auth(
    'account-verified-failed-subtitle'
  )]: `Vennligst ta kontakt med oss via chat så vi kan sørge for å gi deg tilgang.`,
  [intl.auth('account-verified-failed-title')]: `Problemer med å verifisere kontoen`,
  [intl.auth('account-verified-success')]: `Er kontoen verifisert`,
  [intl.auth('already-have-an-account')]: `Har du allerede en konto?`,
  [intl.auth('change-password')]: `Endre passord`,
  [intl.auth(
    'change-password-failed'
  )]: `Det oppstod et problem med å endre passordet ditt`,
  [intl.auth('change-password-success')]: `Nå kan du logge inn med ditt nye passord!`,
  [intl.auth('change-password-title')]: `Endre passord`,
  [intl.auth('company-already-exists')]: `Selskapet er allerede registrert`,
  [intl.auth('company-label')]: `Selskap`,
  [intl.auth('company-placeholder')]: `Søk etter ditt selskap`,
  [intl.auth('confirm-new-password-label')]: `Bekreft nytt passord`,
  [intl.auth('confirm-new-password-placeholder')]: `Bekreft ditt nye passord`,
  [intl.auth('contact-us')]: `Kontakt oss`,
  [intl.auth('do-not-think-email-received')]: `Finner du ikke e-posten i innboksen?`,
  [intl.auth('email')]: `E-post`,
  [intl.auth(
    'email-already-exists'
  )]: `Denne e-postadressen er allerede registert. Kontakt oss på contact@risika.dk`,
  [intl.auth('email-label')]: `E-post`,
  [intl.auth('email-placeholder')]: `navn@firmanavn.com`,
  [intl.auth('email-sent-to')]: `Vi sendte e-posten til`,
  [intl.auth('email-should-have-been-resend')]: `Du skal ha mottatt en ny e-post`,
  [intl.auth('finalize-account')]: `Fullfør registrering`,
  [intl.auth('first-name-label')]: `Fornavn`,
  [intl.auth('first-name-placeholder')]: `Navnet ditt`,
  [intl.auth('forgot-password')]: `Glemt passordet?`,
  [intl.auth(
    'forgot-password-email-subtitle'
  )]: `Hvis du ikke kan se e-posten i innboksen`,
  [intl.auth('forgot-password-email-title')]: `Vi har sendt deg en e-post!`,
  [intl.auth('forgot-password-failed')]: `Beklager`,
  [intl.auth(
    'forgot-password-subtitle'
  )]: `Skriv inn e-posten din og vi sender deg en lenke som lar deg endre passordet`,
  [intl.auth('forgot-password-title')]: `Glemt passordet?`,
  [intl.auth('get-me-in')]: `Gi meg tilgang`,
  [intl.auth('get-started-free')]: `Kom i gang gratis →`,
  [intl.auth('go-back')]: `Gå tilbake`,
  [intl.auth('go-back-to-log-in')]: `Gå tilbake for å logge inn`,
  [intl.auth('invalid-local-id')]: `Bruk et gyldig Organisasjonsnummer`,
  [intl.auth('last-name-label')]: `Ettenavn`,
  [intl.auth('last-name-placeholder')]: `Etternavnet ditt`,
  [intl.auth('log-in')]: `Logg inn`,
  [intl.auth('login-header')]: `Logg inn på kontoen din`,
  [intl.auth('login-to-your-account')]: `Logg inn på kontoen din→`,
  [intl.auth('min-password-length')]: `Minst 8 tegn`,
  [intl.auth('new-email-sent')]: `Vi har sendt en ny e-post`,
  [intl.auth('new-password-label')]: `Nytt passord`,
  [intl.auth('new-password-placeholder')]: `Ditt nye passord`,
  [intl.auth('no-account-yet')]: `Har du ikke en konto enda?`,
  [intl.auth('password-label')]: `Passord`,
  [intl.auth('password-placeholder')]: `Ditt passord`,
  [intl.auth('personal-information')]: `Personlig informasjon`,
  [intl.auth('phone')]: `Telefon`,
  [intl.auth('phone-number-label')]: `Telefonnummer`,
  [intl.auth('please-use-work-mail')]: `Vennligst bruk arbeidsmail`,
  [intl.auth('register-account')]: `Lag konto`,
  [intl.auth('register-account-subtitle')]: `Alle felter må fylles ut`,
  [intl.auth('register-header')]: `Lag din konto`,
  [intl.auth('remembered-your-password')]: `Husket du passordet?`,
  [intl.auth('resend-email')]: `Send e-post på nytt`,
  [intl.auth('send-email')]: `Send e-post`,
  [intl.auth('session-expired')]: `Økten er utløpt. Logg inn på nytt.`,
  [intl.auth(
    'showcase-subtitle'
  )]: `Risika hjelper B2B-selskaper med å drive forretning på tvers av Skandinavia ved å tilby riktige og nøyaktige kredittvurderinger basert på informasjon av høy kvalitet.`,
  [intl.auth('showcase-title')]: `Den enkle måten å drive en sunn virksomhet`,
  [intl.auth('sso-login')]: `Logg inn med Microsoft`,
  [intl.auth('terms-of-service')]: `Vilkår for bruk`,
  [intl.auth('understand-and-accept')]: `Jeg forstår og godtar`,
  [intl.auth(
    'user-registered-subtitle'
  )]: `Vi har sendt deg en e-post for å verifisere kontoen. Så snart du har gjort det kan du begynne å bruke Risika`,
  [intl.auth('user-registered-title')]: `Verifiser din konto`,
  [intl.auth('user-with-email-exists')]: `Det er allerede en bruker med den e-posten`,
  [intl.auth('work-email-label')]: `Arbeidsmail`,
  [intl.auth('wrong-email-or-password')]: `Feil e-post eller passord!`,
  [intl.auth('your-company')]: `Ditt selskap`,
  [intl.companyHierarchy(
    'BENEFICIAL_OWNER_AND_LEGAL_OWNER'
  )]: `Juridisk eier og reel rettighetshaver`,
  [intl.companyHierarchy('LEGAL_OWNER')]: `Juridisk eier`,
  [intl.companyHierarchy('BENEFICIAL_OWNER')]: `Reell rettighetshaver`,
  [intl.companyHierarchy('equity')]: `Egenkapital`,
  [intl.companyHierarchy('local-id')]: `Lokal id`,
  [intl.companyHierarchy('risika-score')]: `Risika score`,
  [intl.companyHierarchy('CEO')]: `CEO`,
  [intl.companyHierarchy('profit-loss')]: `Årets resultat`,
  [intl.companyHierarchy('accessories')]: `Tilbehør`,
  [intl.companyHierarchy('collapse-all')]: `Skjul alt`,
  [intl.companyHierarchy('coming-soon')]: `Kommer snart`,
  [intl.companyHierarchy('company_information')]: `Selskapsinformasjon`,
  [intl.companyHierarchy('credit-max:')]: `Anbefalt kredittgrense:`,
  [intl.companyHierarchy('d3-child-name')]: `Datterselskap til`,
  [intl.companyHierarchy('detailed-view')]: `Detaljert visning`,
  [intl.companyHierarchy('equity:')]: `Egenkapital:`,
  [intl.companyHierarchy('expand-all')]: `Utvid alle {number} selskapene`,
  [intl.companyHierarchy('expand-all-d3')]: `Utvid alle`,
  [intl.companyHierarchy('header')]: `Selskapshierarki`,
  [intl.companyHierarchy('list-view')]: `Listevisning`,
  [intl.companyHierarchy('tree-view')]: `Trevisning`,
  [intl.companyHierarchy('owners')]: `{number} eiere {toggled}`,
  [intl.companyHierarchy('subsidiaries')]: `{number} datterselskaper {toggled}`,
  [intl.companyHierarchy('display-props')]: `Vis egenskaper`,
  [intl.companyHierarchy('listed-in')]: `Oppført i:`,
  [intl.companyHierarchy('local-id:')]: `Lokal id:`,
  [intl.companyHierarchy('monitor-all')]: `Overvåk alt`,
  [intl.companyHierarchy('name')]: `Navn`,
  [intl.companyHierarchy('no-credit-score')]: `Selskapet har ingen kredittscore`,
  [intl.companyHierarchy('no-legal-owners')]: `Ingen juridiske eiere`,
  [intl.companyHierarchy('no-real-owners')]: `Ingen reelle eiere`,
  [intl.companyHierarchy('ownership')]: `Eierskap`,
  [intl.companyHierarchy('profit_loss')]: `Årets resultat`,
  [intl.companyHierarchy('profit_loss:')]: `Årets resultat:`,
  [intl.companyHierarchy.relations]: ` `,
  [intl.companyHierarchy(
    'save-view-description'
  )]: `"""Her kan du lagre visningen av hierarkiet for denne økten. Etter du har logget ut forsvinner de lagrede visningene"`,
  [intl.companyHierarchy('save-view-title')]: `Lagre visning av hierarki`,
  [intl.companyHierarchy('select-list')]: `Velg en liste`,
  [intl.companyHierarchy('sort_by')]: `Sorter etter`,
  [intl.companyHierarchy['credit-policy']]: ` `,
  [intl.companyHierarchy('monitor')]: `Observere`,
  [intl.companyHighlights('click-for-more')]: `klikk for mer`,
  [intl.companyHighlights('title')]: `Selskapets Høydepunkter`,
  [intl.companyHighlights('profit-loss')]: `Årets resultat`,
  [intl.fraudIndicators('title')]: 'Svindelindikatorer',
  [intl.fraudIndicators('address')]: 'Adresse',
  [intl.fraudIndicators('financial')]: 'Finansiell',
  [intl.companyInfo('ad-protection')]: `Reklamebeskyttelse`,
  [intl.companyInfo(
    'ad-protection-allowed'
  )]: `Tillatt å kontakte dette selskapet med uønsket reklame.`,
  [intl.companyInfo(
    'ad-protection-denied'
  )]: `Dette selskapet har nektet å bli kontaktet med uønsket reklame.`,
  [intl.companyInfo('add-internal-id-label')]: `Intern ID`,
  [intl.companyInfo('add-internal-id-title')]: `Legg til intern ID`,
  [intl.companyInfo('address')]: `Adresse`,
  [intl.companyInfo('address-changes-count')]: 'Adresseendringer',
  [intl.companyInfo('auditors')]: `Revisorer`,
  [intl.companyInfo('bank')]: `Bank`,
  [intl.companyInfo('company-id')]: `Lokalt selskaps-ID`,
  [intl.companyInfo(
    'company-info-re-arrange'
  )]: `Skreddersy generell selskapsinformasjon etter dine behov ved å dra i feltene nedenfor!`,
  [intl.companyInfo('company-name')]: `Selskapsnavn`,
  [intl.companyInfo('company-name-changes-count')]: 'Selskapsnavn endringer',
  [intl.companyInfo('company-name-changes-count-explanation')]:
    'Dette kan avvike fra endringer i registrerte firmanavn. Å kunne validere faktiske endringer i firmanavn hjelper oss med å identifisere mønstre som kan indikere risiko',
  [intl.companyInfo('company-name-required')]: `Selskapsnavn *`,
  [intl.companyInfo('company-type')]: `Selskapsform`,
  [intl.companyInfo('date-of-establishment')]: `Stiftelsesdato`,
  [intl.companyInfo('date-of-incorporation')]: `Etablert før 1900`,
  [intl.companyInfo('ean')]: `EAN Number`,
  [intl.companyInfo('edit-internal-id-label')]: `Intern ID`,
  [intl.companyInfo('edit-internal-id-title')]: `Rediger intern ID`,
  [intl.companyInfo('email')]: `E-post`,
  [intl.companyInfo('employees')]: `Ansatte`,
  [intl.companyInfo('financial_year')]: `Regnskapsår`,
  [intl.companyInfo('founders_auditors')]: `Grunnleggere`,
  [intl.companyInfo('fte')]: `FTA (Fulltidsansatte)`,
  [intl.companyInfo('industry')]: `Industri`,
  [intl.companyInfo('internal-id')]: `Intern ID`,
  [intl.companyInfo('invalid')]: `Ugyldig`,
  [intl.companyInfo('land_value')]: `Tomteverdi`,
  [intl.companyInfo('listed')]: `Oppført`,
  [intl.companyInfo('number-of-employees')]: `Antall ansatte`,
  [intl.companyInfo('phone')]: `Telefon`,
  [intl.companyInfo('powers-to-bind')]: `Tegningsregel`,
  [intl.companyInfo('production-units')]: `Produksjonsenheter`,
  [intl.companyInfo('property_id')]: `eiendoms-ID`,
  [intl.companyInfo('property_type')]: `Eiendomstype`,
  [intl.companyInfo('property_value')]: `Eiendomsverdi`,
  [intl.companyInfo('purpose')]: `Formål`,
  [intl.companyInfo('registered-for-vat')]: `Momsregistrert`,
  [intl.companyInfo('secondary-names')]: `Sekundære navn`,
  [intl.companyInfo('share-capital')]: `Aksjekapital`,
  [intl.companyInfo('source')]: `Kilde`,
  [intl.companyInfo('tinglysning')]: `Eiendom`,
  [intl.companyInfo('title')]: `Generell Informasjon om selskapet`,
  [intl.companyInfo('valid-from')]: `Gyldig fra`,
  [intl.companyInfo('valid-to')]: `Gyldig til`,
  [intl.companyInfo('valuation_date')]: `Verdivurdering dato`,
  [intl.companyInfo('webpage')]: `Nettside`,
  [intl.companyInfo('number-of-employees-data-display')]:
    '{date, select, undefined {N/A} other {{date}}}: {employeesInterval} {employeesNumber, select, undefined {{fte, select, undefined {} other {(}}} other {({employeesNumber} ansatte}}{fte, select, undefined {{employeesNumber, select, undefined {} other {)}}} other {{employeesNumber, select, undefined {} other {, }}{fte} Årsverk)}}',
  [intl.companyInfo('address-changes-count-explanation')]:
    'Dette kan avvike fra registrerte adresseendringer. Å kunne validere faktiske adresseendringer hjelper oss med å identifisere mønstre som kan indikere svindelaktivitet',

  [intl.companyRoles('advanced-view')]: `Avansert`,
  [intl.companyRoles('analysis-of-bankruptcies')]: `Analyse av konkurser`,
  [intl.companyRoles('before-1987')]: `Før 1987`,
  [intl.companyRoles('board-of-directors')]: `Styret`,
  [intl.companyRoles('get-overview')]: `Få oversikt`,
  [intl.companyRoles('hide-historical-data')]: `Gjem historiske data`,
  [intl.companyRoles('historical-management')]: `Historisk administrasjon`,
  [intl.companyRoles('history-hide')]: `Skjul historikk`,
  [intl.companyRoles('history-show')]: `Vis historikk`,
  [intl.companyRoles('legal-owners')]: `Juridiske eiere`,
  [intl.companyRoles('minimize-table')]: `Minimer tabell`,
  [intl.companyRoles('month')]: `1 måned`,
  [intl.companyRoles('months')]: `{months} måneder`,
  [intl.companyRoles('no-board-of-directors')]: `Ingen bestyrelse funnet`,
  [intl.companyRoles('no-information')]: `Informasjon utilgjengelig`,
  [intl.companyRoles('no-management')]: `Ingen ledelse funnet`,
  [intl.companyRoles('no-owners')]: `Ingen eiere`,
  [intl.companyRoles('other-positions')]: `se mer...`,
  [intl.companyRoles('real-owners')]: `Reelle eiere`,
  [intl.companyRoles('see-hierarchy')]: `Se selskapshierarki`,
  [intl.companyRoles('see-historical-data')]: `Se historiske data`,
  [intl.companyRoles('shares')]: `aksjer`,
  [intl.companyRoles('show-all')]: `Vis alle`,
  [intl.companyRoles('show-full-table')]: `Vis hele tabellen`,
  [intl.companyRoles('show-less')]: `Vis mindre`,
  [intl.companyRoles('simple-view')]: `Enkel`,
  [intl.companyRoles('subsidiaries')]: `Datterselskaper`,
  [intl.companyRoles('title')]: `Ledelse`,
  [intl.companyRoles('title-owners')]: `Eiere`,
  [intl.companyRoles('total')]: `Totalt:`,
  [intl.companyRoles('total-owners')]: `Total antall eiere`,
  [intl.companyRoles('year')]: `1 år`,
  [intl.companyRoles('years')]: `{years} år`,
  [intl.companyRoles('title-map')]: 'Kart',
  [intl.confirmAction('agree')]: `Enig`,
  [intl.confirmAction('cancel')]: `Avbryt`,
  [intl.confirmAction('confirm')]: `Bekreft`,
  [intl.confirmAction('delete')]: `Slett`,
  [intl.confirmAction('save')]: `Lagre`,
  [intl.country('de')]: `Tyskland`,
  [intl.country('dk')]: `Danmark`,
  [intl.country('fi')]: `Finland`,
  [intl.creditPolicy('fi')]: `Finland`,
  [intl.generic('finland')]: `Finland`,
  [intl.generic('FINLAND')]: `FINLAND`,
  [intl.riskMonitoringOverview('fi_FI')]: `Finland`,
  [intl.country('no')]: `Norge`,
  [intl.country('se')]: `Sverige`,
  [intl.country('uk')]: `Storbritannia`,
  [intl.creditCheck('auditor')]: `Revisorinformasjon`,
  [intl.creditCheck(
    'boundary-line-1'
  )]: `Det oppsto et problem under behandlingen av forespørselen din. Prøv igjen senere!`,
  [intl.creditCheck(
    'boundary-line-2'
  )]: `Hvis det ikke fungerer, kan du prøve å logge av og inn igjen.`,
  [intl.creditCheck(
    'boundary-line-3'
  )]: `Vi har fått beskjed om problemet og jobber i full fart for å løse det.`,
  [intl.creditCheck('beneficial_owner')]: `Reelle eiere`,
  [intl.creditCheck('cant-score-comapny')]: `Vi kunne ikke rangere dette selskapet!`,
  [intl.creditCheck('check-use-fail')]: `Ingen informasjon for valgt selskap`,
  [intl.creditCheck('check-use-success')]: `Du brukte en kredittsjekk`,
  [intl.creditCheck(
    'credit-check-re-arrange'
  )]: `Skreddersy kredittsjekksiden etter dine behov ved å dra feltene nedenfor rundt!`,
  [intl.creditCheck('from')]: `Fra`,
  [intl.creditCheck('generate-report')]: `Last ned PDF-Rapport`,
  [intl.creditCheck('highlights')]: `Høydepunkter`,
  [intl.creditCheck('historical-scores')]: `Se historisk score`,
  [intl.creditCheck('monitor-company')]: `Overvåk selskap`,
  [intl.creditCheck('name')]: `Navn`,
  [intl.creditCheck('no-company-redirect')]: `Ingen selskaper valgt`,
  [intl.creditCheck(
    'no-data'
  )]: `Selskapshierarki er foreløpig utilgjengelig for dette landet`,
  [intl.creditCheck('no-subsidiaries')]: `Selskapet har ingen datterselskaper`,
  [intl.creditCheck('otherPosition')]: `Andre stillinger`,
  [intl.creditCheck('ownership')]: `Eierskap`,
  [intl.creditCheck('pep')]: `PEP-liste`,
  [intl.creditCheck('period')]: `Årsrapportperiode`,
  [intl.creditCheck('position')]: `Stilling`,
  [intl.creditCheck('report-button')]: `Del rapport`,
  [intl.creditCheck('select-list')]: `Velg en liste`,
  [intl.creditCheck('seniority')]: `Ansiennitet`,
  [intl.creditCheck('share')]: `Del`,
  [intl.creditCheck('share-internal-header')]: `Folk i organisasjonen`,
  [intl.creditCheck('share-report')]: `Del PDF-Rapport`,
  [intl.creditCheck(
    'share-report-description'
  )]: `Send kredittrapport til en bestemt e-post eller til noen i organisasjonen fra listen nedenfor.`,
  [intl.creditCheck(
    'share-report-message'
  )]: `Legg til en tilpasset melding som sendes med rapporten`,
  [intl.creditCheck('shares')]: `Aksjer`,
  [intl.creditCheck('specialRights')]: `Spesielle merknader`,
  [intl.creditCheck('stop-monitor-company')]: `Stopp overvåking`,
  [intl.creditCheck('subheader-placeholder')]: `Mottakers e-post`,
  [intl.creditCheck('title')]: `Kredittsjekk`,
  [intl.creditCheck('to')]: `Til`,
  [intl.creditCheck(
    'use-check-for-auditor-title'
  )]: `Sikker på at du vil bruke en kreditt?`,
  [intl.creditCheck('use-credit')]: `Bruk kreditt`,
  [intl.creditCheck('votingRights')]: `Stemmerettigheter`,
  [intl.creditPolicy(
    "AUDITOR'S REPORT ON EXTENDED REVIEW"
  )]: `Revisjonsberetning om utvidet gjennomgang`,
  [intl.creditPolicy("AUDITOR'S REPORT")]: `Revisjonsberetning`,
  [intl.creditPolicy(
    "AUDITOR'S REPORTS (OTHER NON-ASSURANCE REPORTS)"
  )]: `Revisjonsberetning (Andre ikke-forsikringsrapporter)`,
  [intl.creditPolicy(
    "THE INDEPENDENT AUDITOR'S REPORTS (REVIEW)"
  )]: `Uavhengig revisors rapporter (gjennomgang)`,
  [intl.creditPolicy('COMPILATION OF FINANCIAL STATEMENTS')]:
    'Samling av økonomiske rapporter',
  [intl.creditPolicy('1')]: `1`,
  [intl.creditPolicy('1-and-more')]: `1 og flere`,
  [intl.creditPolicy('1-year')]: `1 år`,
  [intl.creditPolicy('10-and-more')]: `10`,
  [intl.creditPolicy('2')]: `2`,
  [intl.creditPolicy('2-and-more')]: `2 og flere`,
  [intl.creditPolicy('2-year')]: `2 år`,
  [intl.creditPolicy('3')]: `3`,
  [intl.creditPolicy('3-and-more')]: `3 og flere`,
  [intl.creditPolicy('3-year')]: `3 år`,
  [intl.creditPolicy('4')]: `4`,
  [intl.creditPolicy('4-and-more')]: `4 og flere`,
  [intl.creditPolicy('5')]: `5`,
  [intl.creditPolicy('6')]: `6`,
  [intl.creditPolicy('7')]: `7`,
  [intl.creditPolicy('8')]: `8`,
  [intl.creditPolicy('5-and-more')]: `5 og flere`,
  [intl.creditPolicy('6-and-more')]: `6 og flere`,
  [intl.creditPolicy('7-and-more')]: `7 og flere`,
  [intl.creditPolicy('8-and-more')]: `8 og flere`,
  [intl.creditPolicy('9-and-more')]: `9 og flere`,
  [intl.creditPolicy('NO AUDITOR ASSISTANCE')]: `Ingen revisjonsbistand`,
  [intl.creditPolicy('POLICY_AGE')]: `Selskapet er for ungt`,
  [intl.creditPolicy(
    'POLICY_AUDITOR_ASSISTANCE'
  )]: `Revisjonserklæringen er ikke tillatt`,
  [intl.creditPolicy('POLICY_NUMBER_OF_EMPLOYEES')]: `Antall ansatte`,
  [intl.creditPolicy('POLICY_BANKRUPTCIES')]: `For mange konkurser`,
  [intl.creditPolicy('POLICY_BANKRUPTCIES_LONG')]: `For mange konkurser`,
  [intl.creditPolicy('POLICY_COMPANY_TYPE')]: `Avvist selskapsform`,
  [intl.creditPolicy('POLICY_DISTANCE_TO_CEO')]: `Stor avstand til CEO`,
  [intl.creditPolicy('POLICY_ENTITY_ID')]: `Avvist person`,
  [intl.creditPolicy('POLICY_INDUSTRY')]: `Industri avvist`,
  [intl.creditPolicy('POLICY_LOCAL_ID')]: `Selskap avvist`,
  [intl.creditPolicy('POLICY_AUDITOR_HAS_ID')]: `Revisor uten MNE-nummer`,
  [intl.creditPolicy('POLICY_NEGATIVE_EQUITY')]: `Negativ egenkapital`,
  [intl.creditPolicy('POLICY_PHONE_NUMBER_REGISTRY')]: `Telefonnummer er ikke registrert`,
  [intl.creditPolicy('POLICY_PREPAID_PHONE_NUMBER')]: `Kontantkort`,
  [intl.creditPolicy('POLICY_RISIKA_SCORE')]: `Lav Risika Score`,
  [intl.creditPolicy('POLICY_VAT')]: `MVA`,
  [intl.creditPolicy(
    'activate-advanced-view'
  )]: `Dette er en avansert funksjon. Venligst kontakt oss hvis du vil prøve den!`,
  [intl.creditPolicy(
    'age-limitation-information'
  )]: `Velg minimum selskapsalder du vil tillate i din kredittpolitikk`,
  [intl.creditPolicy('age-limitation-title')]: `Minimum selskapsalder`,
  [intl.creditPolicy('allow-phone-number')]: `Tillat`,
  [intl.creditPolicy(
    'are-you-sure'
  )]: `Det ser ut som at du har gjort endringer i din kredittpolitikk. Er du sikker på at du vil forlate uten å lagre?`,
  [intl.creditPolicy(
    'are-you-sure-dialog'
  )]: `Denne handlingen vil slå av kredittpolitikk for hele organisasjonen. Er du sikker på at du vil fortsette?`,
  [intl.creditPolicy(
    'are-you-sure-dialog-clear-field'
  )]: `Denne handlingen vil slette alle oppføringene i dette feltet. Er du sikker på at du vil fortsette?`,
  [intl.creditPolicy(
    'auditor-assistance-information'
  )]: `Hvor mange tilknyttede konkurser i løpet av de siste 10 årene vil kredittpolitikken din tillate?`,
  [intl.creditPolicy('auditor-assistance-title')]: `Tillatte former for revisjonsbistand`,
  [intl.creditPolicy('auditor-placeholder')]: `Velg mange`,
  [intl.creditPolicy(
    'bankruptcy-short-limit-information'
  )]: `Hvor mange tilknyttede konkurser i løpet av de siste 10 årene vil kredittpolitikken din tillate?`,
  [intl.creditPolicy(
    'bankruptcy-long-limit-information'
  )]: `Hvor mange tilknyttede konkurser i løpet av de siste 50 årene vil kredittpolitikken din tillate?`,
  [intl.creditPolicy(
    'bankruptcy-short-limit-title'
  )]: `Tilknyttede konkurs (siste 10 år)`,
  [intl.creditPolicy(
    'bankruptcy-long-limit-title'
  )]: `Maksimalt antall tilknyttede konkurser (50 år)`,
  [intl.creditPolicy(
    'company-does-not-comply-with-policy'
  )]: `Selskapet overholder ikke kredittpolitikken`,
  [intl.creditPolicy(
    'company-types-information'
  )]: `Avvis selskapsform i din kredittpolitikk`,
  [intl.creditPolicy('company-types-title')]: `Avviste selskapsformer`,
  [intl.creditPolicy('copied-success')]: `Policy ble kopiert`,
  [intl.creditPolicy('copy-policy')]: `Kopier policy`,
  [intl.creditPolicy('copy-policy-title')]: `Velg landet og feltene du vil kopiere!`,
  [intl.creditPolicy(
    'distance-to-ceo-information'
  )]: `CEOs avstand i km fra selskapsadresse`,
  [intl.creditPolicy('distance-to-ceo-title')]: `Afstand til CEO`,
  [intl.creditPolicy('dk')]: `Danmark`,
  [intl.creditPolicy('dont-allow-phone-number')]: `Ikke tillat`,
  [intl.creditPolicy('enter-number')]: `Angi avstand (km)`,
  [intl.creditPolicy(
    'exclude-companies-information'
  )]: `Avvis spesifikke selskaper i din kredittpolitikk`,
  [intl.creditPolicy(
    'exclude-companies-title'
  )]: `Avvis spesifikke selskaper i din kredittpolitikk`,
  [intl.creditPolicy(
    'exclude-persons-information'
  )]: `Avvis spesifikke personer i din kredittpolitikk`,
  [intl.creditPolicy('exclude-persons-title')]: `Avvis personer`,
  [intl.creditPolicy(
    'exclude-vat-information'
  )]: `Skal et selskap være momsregistrert, eller skal det bare være under den tillatte grense for et land?`,
  [intl.creditPolicy(
    'exclude-vat-title'
  )]: `Ekskluder selskaper som ikke er momsregistrerte`,
  [intl.creditPolicy('less-than-1')]: `Mindre enn 1`,
  [intl.creditPolicy('more-than-3')]: `3 og flere`,
  [intl.creditPolicy('more-than-4')]: `4 og flere`,
  [intl.creditPolicy('more-than-3-years')]: `Mer enn 3 år`,
  [intl.creditPolicy('more-than-4-years')]: `Mer enn 4 år`,
  [intl.creditPolicy('more-than-5')]: `5 og flere år`,
  [intl.creditPolicy('name_of_auditor')]: `Navn på revisor`,
  [intl.creditPolicy(
    'negative-equity-information'
  )]: `Velg hvor mange år uten negativ egenkapital du vil tillate i din kredittpolitikk`,
  [intl.creditPolicy('negative-equity-title')]: `Siste år uten negativ egenkapital`,
  [intl.creditPolicy('no')]: `Norge`,
  [intl.creditPolicy('no-conflicts')]: `Ingen konflikter med din kredittpolitikk.`,
  [intl.creditPolicy('none')]: `Ingen`,
  [intl.creditPolicy('off')]: `Tillat`,
  [intl.creditPolicy(
    'phone-number-registry-information'
  )]: `Er telefonnummeret registrert i telefonboken`,
  [intl.creditPolicy('phone-number-registry-title')]: `Registrert telefonnummer`,
  [intl.creditPolicy(
    'policy-not-available-for-non-admin-users'
  )]: `Kredittpolitikken er bare tilgjengelig for adminbrukere i organisasjonen. Hvis du mener at du bør kunne redigere kredittpolitikken, kontakt din administrator eller oss på contact@risika.dk`,
  [intl.creditPolicy('policy-off')]: `Policy AV`,
  [intl.creditPolicy('policy-on')]: `Policy PÅ`,
  [intl.creditPolicy('policy-switch-failed')]: `Kunne ikke bytte kredittpolitikk`,
  [intl.creditPolicy(
    'policy-switch-off-success'
  )]: `Slått av kredittpolitikk for {country}`,
  [intl.creditPolicy(
    'policy-switch-on-success'
  )]: `Slått på kredittretningslinjene for {country}`,
  [intl.creditPolicy(
    'prepaid-phone-number-information'
  )]: `Bli varslet hvis et selskap er opprettet med et kontantkort`,
  [intl.creditPolicy('prepaid-phone-number-title')]: `Tillat kontantkort`,
  [intl.creditPolicy(
    'risika-score-information'
  )]: `Velg minimum Risika Score du vil tillate i din kredittpolitikk`,
  [intl.creditPolicy('risika-score-title')]: `Minimum Risika Score`,
  [intl.creditPolicy(
    'save-failed'
  )]: `Kunne ikke lagre kredittpolitikk for {country}. Prøv igjen senere!`,
  [intl.creditPolicy('save-success')]: `Lagret kredittpolitikk for {country}`,
  [intl.creditPolicy('se')]: `Sverige`,
  [intl.creditPolicy('search')]: `Søk og legg til`,
  [intl.creditPolicy('select')]: `Velg`,
  [intl.creditPolicy('select-many')]: `Velg flere`,
  [intl.creditPolicy('should-be')]: `Ikke tillat`,
  [intl.creditPolicy(
    'should-be-and-exceeded'
  )]: `Bare hvis det er over den lokale grensen for MVA`,
  [intl.creditPolicy('specific-address-title')]: `Ekskluder adresser`,
  [intl.creditPolicy(
    'specific-industry-information'
  )]: `Avvis spesifikke industrier i din kredittpolitikk`,
  [intl.creditPolicy('specific-industry-title')]: `Avvis industrier`,
  [intl.creditPolicy(
    'too-many-values'
  )]: `Verdiene for {country} overskrider grensen. Kun lagt til 500 selskaper!`,
  [intl.creditPolicy('type_of_assistance')]: `Type assistanse`,
  [intl.creditPolicy('uk')]: `Storbritannia`,
  [intl.creditPolicy('up-to-1')]: `1 år`,
  [intl.creditPolicy('up-to-2')]: `2 år`,
  [intl.creditPolicy('up-to-3')]: `3 år`,
  [intl.creditPolicy('up-to-4')]: `4 år`,
  [intl.creditPolicy('up-to-5')]: `5 år`,
  [intl.creditPolicy('auditor-id')]: `Revisor må ha et identifikasjonsnummer (MNE)`,
  [intl.creditPolicy(
    'auditor-id-information'
  )]: `Tillat kun godkjente revisorer med MNE-nummer. MNE-nummeret er et unikt og offentlig identifikasjonsnummer for alle godkjente revisorer.`,
  [intl.creditPolicyNew(
    'restriction-modal-title'
  )]: `Oppgrader til Pro og lås opp avanserte funksjoner`,
  [intl.creditPolicyNew(
    'restriction-modal-list-1'
  )]: `Tilpass kreditt policyen din med 12 tilleggsvariabler`,
  [intl.creditPolicyNew(
    'restriction-modal-list-2'
  )]: `Få tilgang til data på tvers av 3 nordiske land`,
  [intl.creditPolicyNew(
    'restriction-modal-list-3'
  )]: `Overvåk opptil 500 selskaper per måned`,
  [intl.creditPolicyNew(
    'restriction-modal-paragraph'
  )]: `Snakk med en salgsrepresentant for å diskutere dine behov og finne den beste løsningen for din bedrift`,
  [intl.creditPolicyNew('credit-policy-page-title')]: `Retningslinjer for kreditt`,
  [intl.creditPolicyNew('industry-type')]: `Bransje`,
  [intl.creditPolicyNew('back-to-home')]: `Tilbake til startsiden`,
  [intl.creditPolicyNew('companies-in-country')]: `Selskaper i {country}`,
  [intl.creditPolicyNew(
    'credit-policy-builder-history-title'
  )]: `Kredittretningslinjebygger`,
  [intl.creditPolicyNew('company-type')]: `Selskapstype`,
  [intl.creditPolicyNew('risika-score')]: `Risika Score`,
  [intl.creditPolicyNew('cvr-number')]: `Organisasjonsnummer`,
  [intl.creditPolicyNew('prepaid-phone')]: `Kontantkort`,
  [intl.creditPolicyNew('distance-to-ceo')]: `Avstand til CEO`,
  [intl.creditPolicyNew('audit_selected')]: ` `,
  [intl.creditPolicyNew('equity')]: `Inkludert utbytte`,
  [intl.creditPolicyNew('accept-credit')]: `Godta kreditt`,
  [intl.creditPolicyNew('manual-check')]: `Manuell kontroll`,
  [intl.creditPolicyNew('reject-credit')]: `Avvis kreditt`,
  [intl.creditPolicyNew('condition')]: `Betingelse`,
  [intl.creditPolicyNew('type')]: `Type`,
  [intl.creditPolicyNew('tolerance')]: `Toleranse`,
  [intl.creditPolicyNew('delete-rule')]: `Slett en regel`,
  [intl.creditPolicyNew('delete-rule-button-label')]: `Slett`,
  [intl.creditPolicyNew('delete-this-rule')]: `Slett denne regelen`,
  [intl.creditPolicyNew('import-a-policy-button')]: `Importer en policy`,
  [intl.creditPolicyNew('create-a-policy-button')]: `Opprett en ny policy`,
  [intl.creditPolicyNew('terms')]: `Vilkår`,
  [intl.creditPolicyNew('method')]: `Metode`,
  [intl.creditPolicyNew('companies')]: 'Selskaper',
  [intl.creditPolicyNew('credit-definition')]: `Kredittdefinisjon`,
  [intl.creditPolicyNew('define_credit_max')]: `Definer kredittmaks`,
  [intl.creditPolicyNew('define_payment_terms')]: `Definer betalingsbetingelser`,
  [intl.creditPolicyNew('use_risika_recommendation')]: `Bruk Risika anbefaling`,
  [intl.creditPolicyNew('set_your_own')]: `Definer ditt eget`,
  [intl.creditPolicyNew('set_your_own_value')]: `Angi din egen verdi`,
  [intl.creditPolicyNew('percentage_of_risika_score')]: `Prosent av Risika Score`,
  [intl.creditPolicyNew('value')]: `Verdi`,
  [intl.creditPolicyNew('not_greater_than')]: `Ikke større enn`,
  [intl.creditPolicyNew('not_lower_than')]: `Ikke lavere enn`,
  [intl.creditPolicyNew('set-limit')]: `Sett grense (valgfritt)`,
  [intl.creditPolicyNew('create-first-policy')]: 'Begynn å opprette din første policy',
  [intl.creditPolicyNew('create-first-policy-cta')]:
    'Opprett en ny policy fra bunnen av eller ',
  [intl.creditPolicyNew('import')]: 'importer',
  [intl.creditPolicyNew('existing-one')]: ' en eksisterende',
  [intl.creditPolicyNew('you-have-no-policies')]:
    ' Teamet ditt har for øyeblikket ingen policyer',
  [intl.creditPolicyNew('is')]: `Er`,
  [intl.creditPolicyNew('is-not')]: `Er ikke`,
  [intl.creditPolicyNew('equity_without_dividend')]: `Ekskludert utbytte`,
  [intl.creditPolicyNew('greater-than')]: `Større enn`,
  [intl.creditPolicyNew('equal-to')]: `Lik`,
  [intl.creditPolicyNew('less-than')]: `Mindre enn`,
  [intl.creditPolicyNew('between')]: `Mellom`,
  [intl.creditPolicyNew('greater_than')]: `Større enn`,
  [intl.creditPolicyNew('equals')]: `Lik`,
  [intl.creditPolicyNew('less_than')]: `Mindre enn`,
  [intl.creditPolicyNew('select')]: `Velg`,
  [intl.creditPolicyNew('prepaid-phone-status')]: `Status for forhåndsbetalt kontantkort`,
  [intl.creditPolicyNew('registered-prepaid-phone')]: `Registrert kontantkort`,
  [intl.creditPolicyNew('no-prepaid-phone-registered')]: `Ingen kontantkort registrert`,
  [intl.creditPolicyNew('no-data-yet')]: `Fortsatt ingen data...`,
  [intl.creditPolicyNew('distance-to-ceo-node-title')]: `Avstand til daglig leder`,
  [intl.creditPolicyNew('equity-node-title')]: `Egenkapital`,
  [intl.creditPolicyNew('prepaid-phone-node-title')]: `Kontantkort`,
  [intl.creditPolicyNew('something-wrong-with-node-data')]: `Ingen eller ødelagte data`,
  [intl.creditPolicyNew('no-companies-in-list')]: `Ingen selskaper i listen`,
  [intl.creditPolicyNew('action-panel-cancel-button')]: `Avbryt`,
  [intl.creditPolicyNew('action-panel-confirm-button')]: `Bekreft`,
  [intl.creditPolicyNew(
    'manual-check-description-label'
  )]: `Legg til noen notater (valgfritt)`,
  [intl.creditPolicyNew(
    'reject-credit-description-label'
  )]: `Legg til noen notater (valgfritt)`,
  [intl.creditPolicyNew('company-list-description-label')]: `Velg en liste`,
  [intl.creditPolicyNew('company-list-create-new')]: `Opprett ny liste`,
  [intl.creditPolicyNew('search-multi-select-apply')]: `Bruk`,
  [intl.creditPolicyNew('search-multi-select-clear-all')]: `Fjern alle`,
  [intl.creditPolicyNew('please-select')]: `Vennligst velg`,
  [intl.creditPolicyNew('none')]: `Ingen`,
  [intl.creditPolicyNew('1')]: `1`,
  [intl.creditPolicyNew('2')]: `2`,
  [intl.creditPolicyNew('3')]: `3`,
  [intl.creditPolicyNew('4')]: `4`,
  [intl.creditPolicyNew('5')]: `5`,
  [intl.creditPolicyNew('6')]: `6`,
  [intl.creditPolicyNew('7')]: `7`,
  [intl.creditPolicyNew('8')]: `8`,
  [intl.creditPolicyNew('9')]: `9`,
  [intl.creditPolicyNew('10')]: `10`,
  [intl.creditPolicyNew('1-year')]: `1 år`,
  [intl.creditPolicyNew('2-years')]: `2 år`,
  [intl.creditPolicyNew('3-years')]: `3 år`,
  [intl.creditPolicyNew('4-years')]: `4 år`,
  [intl.creditPolicyNew('5-years')]: `5 år`,
  [intl.creditPolicyNew('6-years')]: `6 år`,
  [intl.creditPolicyNew('7-years')]: `7 år`,
  [intl.creditPolicyNew('8-years')]: `8 år`,
  [intl.creditPolicyNew('9-years')]: `9 år`,
  [intl.creditPolicyNew('10-years')]: `10 år`,
  [intl.creditPolicyNew('1-and-more')]: `1 og flere`,
  [intl.creditPolicyNew('2-and-more')]: `2 og flere`,
  [intl.creditPolicyNew('3-and-more')]: `3 og flere`,
  [intl.creditPolicyNew('4-and-more')]: `4 og flere`,
  [intl.creditPolicyNew('5-and-more')]: `5 og flere`,
  [intl.creditPolicyNew('6-and-more')]: `6 og flere`,
  [intl.creditPolicyNew('7-and-more')]: `7 og flere`,
  [intl.creditPolicyNew('8-and-more')]: `8 og flere`,
  [intl.creditPolicyNew('9-and-more')]: `9 og flere`,
  [intl.creditPolicyNew('10-and-more')]: `10 og flere`,
  [intl.creditPolicyNew(
    'not-registered'
  )]: `Ekskluder selskaper som ikke er registrert for MVA`,
  [intl.creditPolicyNew(
    'should-be-registered'
  )]: `Ekskluder kun selskaper som skal være MVA-registrert`,
  [intl.creditPolicyNew('less-than-year')]: `Mindre enn ett år gammel`,
  [intl.creditPolicyNew('1-year-old')]: `1 år gammel`,
  [intl.creditPolicyNew('2-year-old')]: `2 år gammel`,
  [intl.creditPolicyNew('3-year-old')]: `3 år gammel`,
  [intl.creditPolicyNew('4-year-old')]: `4 år gammel`,
  [intl.creditPolicyNew('5-year-old')]: `5 år gammel`,
  [intl.creditPolicyNew(
    'delete-rule-are-you-sure'
  )]: `Er du sikker på at du vil slette denne regelen?`,
  [intl.creditPolicyNew(
    'company-type-desc'
  )]: `List opp selskapstyper du ønsker å avvise fra din policy`,
  [intl.creditPolicyNew('company-years-title')]: `Selskapets levetid`,
  [intl.creditPolicyNew(
    'company-years-desc'
  )]: `Velg minimum antall år et selskap må ha eksistert for å bli godkjent`,
  [intl.creditPolicyNew('industry-type-title')]: `Bransjetype`,
  [intl.creditPolicyNew(
    'industry-type-desc'
  )]: `List opp bransjetypene du ønsker å ekskludere fra din policy.`,
  [intl.creditPolicyNew('number-of-employees-title')]: `Antall ansatte`,
  [intl.creditPolicyNew(
    'number-of-employees-desc'
  )]: `Selskapet har et lavere antall ansatte enn det som er akseptert i kredittpolitikken din`,
  [intl.creditPolicyNew('risika-score-title')]: `Risika score`,
  [intl.creditPolicyNew(
    'risika-score-desc'
  )]: `Fastsett minimum Risiko score som kreves for at et selskap skal godkjennes under din policy`,
  [intl.creditPolicyNew(
    'bankruptcy-short-limit-desc'
  )]: `Velg det maksimale antall tilknyttede konkurs som er tillatt de siste 10 årene for at et selskap skal oppfylle dine policy-kriterier`,
  [intl.creditPolicyNew(
    'bankruptcy-long-limit-desc'
  )]: `Velg det maksimale antallet tilknyttede konkurser i løpet av de siste 50 årene tillatt under din policy`,
  [intl.creditPolicyNew('auditor-id-title')]: `Revisors ID-nummer`,
  [intl.creditPolicyNew(
    'auditor-id-label'
  )]: `Avvis selskaper der revisor ikke kan oppgi et ID-nummer`,
  [intl.creditPolicyNew(
    'distance-to-ceo-desc'
  )]: `Etabler avstandsterskelen for administrerende direktørs nærhet til selskapets hovedkontor for å være kvalifisert for dekning i policy`,
  [intl.creditPolicyNew('people-id-title')]: `Person-ID`,
  [intl.creditPolicyNew('people-id-desc')]: `Avvis spesifikke personer fra din policy`,
  [intl.creditPolicyNew('company-id-title')]: `Bedrifts-ID`,
  [intl.creditPolicyNew(
    'company-id-desc'
  )]: `Avvis spesifikke selskaper fra din politikk`,
  [intl.creditPolicyNew(
    'equity-desc'
  )]: `Angi minimum antall år et selskap må ha opprettholdt positiv egenkapital for å oppfylle kreditt-policy din`,
  [intl.creditPolicyNew('company-vat-title')]: `MVA-nummer`,
  [intl.creditPolicyNew(
    'company-vat-desc'
  )]: `Velg momskriteriene som et selskap må oppfylle for å bli godkjent i henhold til din policy`,
  [intl.creditPolicyNew('company-type-placeholder')]: `Søk etter en selskapstype`,
  [intl.creditPolicyNew('industry-type-placeholder')]: `Søk etter en bransjetype`,
  [intl.creditPolicyNew('search-company-type-placeholder')]: `Søk etter selskap`,
  [intl.creditPolicyNew('search-people-placeholder')]: `Søk etter personer`,
  [intl.creditPolicy('POLICY_DEBT')]: `Gjeld`,
  [intl.creditPolicyNew(
    'debt-desc'
  )]: `Angi den maksimale mengden utestående gjeld som et selskap kan ha for å kvalifisere for din policy`,
  [intl.creditPolicy('POLICY_SHORT_TERM_DEBT')]: `Kortsiktig gjeld`,
  [intl.creditPolicyNew(
    'short-term-debt-desc'
  )]: `Angi den maksimale mengden kortsiktig gjeld et selskap kan ha for å kvalifisere for din policy`,
  [intl.creditPolicy('POLICY_PROFIT_LOSS')]: `Resultat etter skatt`,
  [intl.creditPolicyNew(
    'result-after-tax-desc'
  )]: `Angi det minste nivået av etter-skatt overskudd som kreves for at et selskap skal oppfylle kriteriene for din politikk`,
  [intl.creditPolicy('POLICY_PAYMENT_REMARKS_COUNT')]: `Antall betalingsanmerkninger`,
  [intl.creditPolicyNew(
    'payment-remarks-desc'
  )]: `Spesifiser det maksimale antallet aktive betalingsanmerkninger et selskap kan ha for å kvalifisere for kreditt`,
  [intl.creditPolicy(
    'POLICY_PAYMENT_REMARKS_TOTAL_AMOUNT'
  )]: `Utestående saldo på betalingsanmerkninger`,
  [intl.creditPolicyNew(
    'amount-due-desc'
  )]: `Definer den maksimale utestående saldoen på aktive betalingsanmerkninger et selskap kan ha for å være kvalifisert for kreditt`,
  [intl.creditPolicy(
    'POLICY_PAYMENT_REMARKS_CREDITORS_COUNT'
  )]: `Antall kreditorer knyttet til betalingsanmerkninger`,
  [intl.creditPolicyNew(
    'number-of-creditors-desc'
  )]: `Angi det maksimale antallet kreditorer et selskap kan ha aktive betalingsanmerkninger med for å oppfylle kravene til kredittverdighet`,
  [intl.creditPolicy(
    'POLICY_PAYMENT_REMARKS_MAX_REPAYMENT_TIME'
  )]: `Tid til å tilbakebetale aktive betalingsanmerkninger`,
  [intl.creditPolicyNew(
    'average-repayment-duration-desc'
  )]: `Sett en grense for hvor lenge betalingsanmerkninger kan være aktive før et selskap ikke er kvalifisert i henhold til din politikk`,
  [intl.creditPolicyNew('audit-level-title')]: `Revisjonsnivå`,
  [intl.creditPolicyNew(
    'audit-level-desc'
  )]: `Fastsett det nødvendige revisjonsnivået for at et selskap skal bli godkjent for kreditt`,
  [intl.creditPolicyNew(
    'reject_prepaid_phone'
  )]: `Avvis selskaper med forhåndsbetalt telefon`,
  [intl.creditPolicyNew(
    'reject_registered_phone'
  )]: `Avvis selskaper som ikke har et offentlig registrert telefonnummer`,
  [intl.creditPolicyNew(
    'delete-rule-explanation'
  )]: `Du sletter en regel som er del av en rekke handlinger. Ved å bekrefte vil du slette regelen, og alle handlinger som avhenger av den.`,
  [intl.creditPolicyNew(
    'delete-rule-do-not-show-again'
  )]: `Ikke vis denne meldingen igjen`,
  [intl.creditPolicyNew('dropdown_multi_select')]: `{companyList}`,
  [intl.creditPolicyNew(
    'equity_node_content'
  )]: `Equity {type, select, equity {including dividend} equity_without_dividend {excluding dividend} other {}} is {tolerance, select, greater_than {greater than} equals {equal to} less_than {less than} between {between} other {NONE}} {max, plural, =0 {{min} {currency}} other {{min} {currency} and {max} {currency}}}`,
  [intl.creditPolicyNew('audit_selected-node-text')]: `Selskapet har blitt revidert`,
  [intl.creditPolicyNew('audit-node-text')]: `Selskapet har blitt revidert`,
  [intl.creditPolicyNew('risk-assessment-node-text')]: ` `,
  [intl.creditPolicyNew('company_vat')]:
    'Kombinert med andre faktorer som bedriftens alder, ledelsens ansiennitet og omsetning kan merverdiavgift være en god indikator på svindel. Med mindre de er fritatt, bør de fleste virksomheter være momsregistrerte. Hvis de ikke er det, kan de utgjøre en risiko på grunn av sin størrelse, eller det kan være en indikasjon på at de skjuler deler av virksomheten.',
  [intl.creditPolicyNew(
    'already-active-dialog'
  )]: `The policy "{activeName}" is already active in {country, select,DK {Denmark}SE {Sweden}NO {Norway}other {this country}}. In order to publish "{currentName}", the existing policy will need to be deactivated temporarily.`,
  [intl.creditPolicyNew(
    'status-node-text'
  )]: `Is {active, select, ACTIVE {Active} other {Inactive}}`,
  [intl.creditPolicyNew(
    'prepaid-phone-node-text'
  )]: `Forhåndsbetalt kontantkort registrert`,
  [intl.creditPolicyNew(
    'distance-to-ceo-node-text'
  )]: `Is {tolerance, select, greater_than {greater than} equals {equal to} less_than {less than} between {between} other {...}} {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}`,
  [intl.creditPolicyNew(
    'number-of-employees-node-text'
  )]: `{tolerance, select, greater_than {Greater than} equals {Equal to} less_than {Less than} between {Between} other {...}} {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}`,
  [intl.creditPolicyNew(
    'risika-score-node-text'
  )]: `{tolerance, select, greater_than {Greater than} equals {Equal to} less_than {Less than} between {Between} other {...} {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} and {max}}}`,
  [intl.creditPolicyNew(
    'reject-credit-node-text'
  )]: `{description, select, false {No notes available} other {{description}}}`,
  [intl.creditPolicyNew(
    'manual-check-node-text'
  )]: `{description, select, false {No notes available} other {{description}}}`,
  [intl.creditPolicyNew('approve-credit-max-node-text-credit-max')]: `Kreditt maks:`,
  [intl.creditPolicyNew(
    'approve-credit-max-node-text-not-lower-than'
  )]: `Ikke lavere enn:`,
  [intl.creditPolicyNew(
    'approve-credit-max-node-text-not-greater-than'
  )]: `Ikke større enn:`,
  [intl.creditPolicyNew(
    'adjust-credit-max-node-text'
  )]: `{creditMax} {percentage}% av Risikas anbefaling {lineBreak}`,
  [intl.creditPolicyNew(
    'adjust-credit-max-node-text-currency'
  )]: `{limit} {amount} {currency}`,
  [intl.creditPolicyNew('approve-credit-max-node-text')]: `Bruk Risikas anbefaling`,
  [intl.creditPolicyNew(
    'approve-with-value-node-text'
  )]: `Godkjenn med verdi {value} {currency}`,
  [intl.creditPolicyNew('number-of-employees')]: `Antall ansatte`,
  [intl.creditPolicyNew('risika-score-node-title')]: `Risika Score`,
  [intl.creditPolicyNew('drag-drop-policy')]: `Dra en fil hit (JSON)`,
  [intl.creditPolicyNew('cancel')]: `Avbryt`,
  [intl.creditPolicyNew('get-started')]: `Kom i gang`,
  [intl.creditPolicyNew('export')]: `Eksporter`,
  [intl.creditPolicyNew('duplicate')]: `Dupliser`,
  [intl.creditPolicyNew('edit')]: `Rediger`,
  [intl.creditPolicyNew('edit-policy-settings')]: `Rediger retningslinjeinnstillinger`,
  [intl.creditPolicyNew('delete')]: `Slett`,
  [intl.creditPolicyNew('print')]: `Skriv ut`,
  [intl.creditPolicyNew('share')]: `Del`,
  [intl.creditPolicyNew('rename')]: `Gi nytt navn`,
  [intl.creditPolicyNew('export-success')]: `Kreditpolicyen ble eksportert`,
  [intl.creditPolicyNew('export-error')]: `Noe gikk galt :(`,
  [intl.creditPolicyNew('duplicate-success')]: `Kreditpolicyen ble duplisert`,
  [intl.creditPolicyNew('duplicate-error')]: `Noe gikk galt :(`,
  [intl.creditPolicyNew('delete-success')]: `Kreditpolicyen ble slettet`,
  [intl.creditPolicyNew('delete-error')]: `Noe gikk galt :(`,
  [intl.creditPolicyNew('search-action')]: `Søk etter en handling`,
  [intl.creditPolicyNew('deactivate-and-publish')]: `Deaktiver og publiser`,
  [intl.creditPolicyNew('save-as-draft')]: `Lagre som utkast`,
  [intl.creditPolicyNew('test')]: `Test`,
  [intl.creditPolicyNew('test-policy-title')]: `Test`,
  [intl.creditPolicyNew('test-policy-side-panel-label')]: `Dine lister`,
  [intl.creditPolicyNew(
    'select-a-list-to-test-policy-on'
  )]: `Velg en liste å teste policyen på`,
  [intl.creditPolicyNew(
    'your-policy-will-remain-unpublished'
  )]: `Policyen din vil forbli inaktiv og du kan fortsatt endre i den.`,
  [intl.creditPolicyNew('test-and-download-button')]: `Test og last ned resulater`,
  [intl.creditPolicyNew('company_information')]: `Selskapsinformasjon`,
  [intl.creditPolicyNew('status')]: `Selskapsstatus`,
  [intl.creditPolicyNew('risk_indicators')]: `Risikoindikatorer`,
  [intl.creditPolicyNew('distance_to_ceo')]: `Avstann til CEO`,
  [intl.creditPolicyNew('prepaid_phone')]: `Forhåndsbetalt telefon`,
  [intl.creditPolicyNew('people_search')]: `Personsøk`,
  [intl.creditPolicyNew('company_search')]: `Selskapssøk`,
  [intl.creditPolicyNew('revenue')]: `Inntek`,
  [intl.creditPolicyNew('debt')]: `Gjeld`,
  [intl.creditPolicyNew('relative_debt_change')]: `Relativ gjeldsendring`,
  [intl.creditPolicyNew('ebitda')]: `EBITDA`,
  [intl.creditPolicyNew('ebit')]: `EBIT`,
  [intl.creditPolicyNew('short_term_tax_payable')]: `Kortsiktig skatteplikt`,
  [intl.creditPolicyNew('payment_remarks')]: `Betalingsanmerkninger`,
  [intl.creditPolicyNew('invoice_data')]: `Fakturadata`,
  [intl.creditPolicyNew('debt_to_equity_ratio')]: `Gjeld til egenkapital forhold`,
  [intl.creditPolicyNew('solidity_ratio')]: `Soliditetsgrad`,
  [intl.creditPolicyNew('return_on_assets')]: `Avkastning på eiendeler (ROA)`,
  [intl.creditPolicyNew('return_on_equity')]: `Egenkapitalavkastning`,
  [intl.creditPolicyNew('asset_turnover')]: `Eiendel omsetning`,
  [intl.creditPolicyNew('approve_credit')]: `Godkjenn kreditt`,
  [intl.creditPolicyNew('manual_check')]: `Manuell kontroll`,
  [intl.creditPolicyNew('reject')]: `Avvis kreditt`,
  [intl.creditPolicyNew('publish')]: `Lagre og publiser`,
  [intl.creditPolicyNew('edit-an-active-policy-edit-a-copy')]: `Rediger en kopi`,
  [intl.creditPolicyNew(
    'edit-an-active-policy-edit-this-version'
  )]: `Rediger denne versjonen`,
  [intl.creditPolicyNew(
    'error-loading-policies'
  )]: `Beklager, vi kan ikke laste inn policyene dine for øyeblikket. Prøv igjen senere.`,
  [intl.creditPolicyNew(
    'error-loading-policy'
  )]: `Beklager, vi kan ikke laste inn policyene dine for øyeblikket. Prøv igjen senere.`,
  [intl.creditPolicyNew(
    'error-loading-ecp-builder-actions'
  )]: `Beklager, vi kan ikke laste inn policyene dine for øyeblikket. Prøv igjen senere.`,
  [intl.creditPolicyNew(
    'edit-an-active-policy-body'
  )]: `Du prøver å redigere en aktiv policy, noe som kan påvirke ytelsen til policyen din. Av denne grunn vil vi midlertidig deaktivere policyen din mens du gjør endringer. Hvis du vil at policyen skal forbli aktiv, rediger en kopi i stedet.`,
  [intl.creditPolicyNew(
    'save-incomplete-policy-body'
  )]: `Vi har lagt merke til at noen grener i policyen din ser ut til å være tomme. For at Risika skal anvende kredittpolitikken din nøyaktig, er det viktig å sikre at hver gren er satt med et resultat. Dette vil bidra til å sikre at policyen din tar for seg alle mulige scenarioer.`,
  [intl.creditPolicyNew(
    'save-incomplete-policy-title'
  )]: `Sørg for at policyen din er fullstendig før du publiserer den`,
  [intl.creditPolicyNew(
    'or-else-if-no-data-is-available'
  )]: `Ellers hvis ingen data er tilgjengelig`,
  [intl.creditPolicyNew('pro_tips_title')]: `Pro tips`,
  [intl.creditPolicyNew(
    'tips_payment_remarks'
  )]: `Våre data dekker de siste fire årene og sporer utelukkende aktive betalingsanmerkninger. Dette betyr at betalingsanmerkninger regnes som slike til hele skyldige beløpet er gjort opp. Vi samler inn betalingsanmerkninger fra Intrum, Visma og Kredinor. Du kan finne mer informasjon om dette i personvernreglene våre.`,
  [intl.creditPolicyNew(
    'tips_equity'
  )]: `Egenkapital representerer verdien av en investors andel i et selskap. Egenkapitalen kan enten være negativ eller positiv. Hvis det er positivt, har selskapet nok eiendeler til å dekke sine forpliktelser. Hvis det er negativt, overstiger selskapets forpliktelser dets eiendeler; dersom det varer i en lengere periode, anses dette som balanseinsolvens.`,
  [intl.creditPolicyNew(
    'tips_company_type'
  )]: `Bruk selskapstype for å bruke kredittpolicyreglene på spesifikke typer selskaper på forskjellige måter. For eksempel kan selskapstyper som er eneforhandlere kreve andre kredittvilkår enn børsnoterte selskaper.`,
  [intl.creditPolicyNew(
    'tips_risika_score'
  )]: `Risika vurderer majoritetsselskapenes økonomiske helse ved å bruke Risika-score.


    For selskaper som har et lavt nivå av økonomiske data, evaluerer Risika deres økonomiske helse ved å bruke et enklere risikovurderingsnivå. For eksempel mangler enkeltmannsselskaper vanligvis brede økonomiske data, så mange av disse selskapene blir evaluert med et risikovurderingsnivå.


    For selskaper som har utilstrekkelige økonomiske data, kan ikke Risika vurdere deres økonomiske helse, og derfor gis ingen poengsum eller vurdering. For eksempel i Danmark har mange kirker ikke økonomiske data, så ingen poengsum eller vurdering er gitt.`,
  [intl.creditPolicyNew(
    'tips_prepaid_phone'
  )]: `Forhåndsbetalte telefoner kan være en indikator på at et selskap utfører uredelige aktiviteter. Vi anbefaler kun å sjekke forhåndsbetalte telefoner for private selskaper.`,
  [intl.creditPolicyNew(
    'dk_tips_distance_to_ceo'
  )]: `I Danmark har selskaper hvor administrerende direktør bor mer enn 25 km unna det registrerte hovedkontoret en gjennomsnittlig økonomisk nødsituasjonsrate på 2,83%.`,
  [intl.creditPolicyNew(
    'tips_employees_number'
  )]: `Bruk antall ansatte til å bruke kredittpolicyreglene til bedrifter på forskjellige måter. Antall ansatte kan for eksempel gi en indikasjon på en bedrifts størrelse og økonomiske helse – derfor dens potensielle motstandskraft mot økonomiske kriser.`,
  [intl.creditPolicyNew(
    'tips_audit_selected'
  )]: `Bruk revisjon for å sjekke om et selskap har blitt revidert eller ikke. Et selskap som har gjennomgått en revisjon er mer troverdig og gir sikkerhet for at regnskapet er nøyaktig og pålitelig.`,
  [intl.creditPolicyNew(
    'tips_industry_code'
  )]: `Bruk bransjetype for å bruke dine kredittpolicyregler på selskaper i forskjellige bransjer. For eksempel kan selskapstyper som er i produksjon kreve andre kredittvilkår enn selskaper innen finansielle tjenester.`,
  [intl.creditPolicyNew(
    'tips_local_id_in_monitoring_list'
  )]: `Selskapsliste er en liste over selskaper som er knyttet til det aktuelle selskapet`,
  [intl.creditPolicyNew(
    'tips_company_list'
  )]: `Bruk selskapsliste til å lage regler basert på lister over selskaper du oppretter. Du må laste opp en liste over selskaper og lagre den som en overvåkingsliste før du kan bruke den i policyen din.`,
  [intl.creditPolicyNew(
    'tips_approve_credit'
  )]: `Godkjenn kreditt angir kredittgrensen som skal brukes på en kunde, basert på reglene du har satt opp til dette tidspunktet i policyen din. Bruk enten Risikas anbefaling, eller sett din egen kredittgrense.`,
  [intl.creditPolicyNew(
    'tips_manual_check'
  )]: `Manuell sjekk vil bli brukt på kunder som et resultat av reglene du har satt opp til nå. Dette lar deg vurdere kunder fra sak til sak for å bestemme hvor mye kreditt du vil gi dem, eller ikke.`,
  [intl.creditPolicyNew(
    'tips_company_vat'
  )]: `Kombinert med andre faktorer som selskapets alder, ledelsens erfaring eller omsetning, kan selskapets MVA være en god indikator på svindel. Med mindre de er fritatt, bør de fleste virksomheter være registrert for MVA. Hvis de ikke er det, kan de utgjøre en risiko på grunn av størrelsen eller det kan være et tegn på at de skjuler deler av virksomheten sin.`,
  [intl.creditPolicyNew(
    'tips_reject'
  )]: `Avvist kreditt er en kreditt som har blitt avvist av kredittpolicyen`,
  [intl.creditPolicyNew(
    'something-went-wrong-credit-policy'
  )]: `Noe gikk galt. Oppdater venligst siden. Hvis problemet fortsetter, kontakt kundesupport`,
  [intl.creditPolicyNew('back-to-credit-policy')]: `Tilbake til kredittretningslinjer`,
  [intl.creditPolicyNew(
    'invalid-country-message'
  )]: `Vi kunne ikke lagre retningslinjene dine. Vennligst prøv igjen.`,
  [intl.creditPolicyNew('we-where-not-able-to-save-your-policy')]: `Lagre og aktiver`,
  [intl.creditPolicyNew('save-and-activate')]: `Begynn å lage din første regel`,
  [intl.creditPolicyNew(
    'create-you-first-rule-button'
  )]: `Begynn å lage din første regel`,
  [intl.creditPolicyNew('description-text')]: `Beskrivelse (valgfritt)`,
  [intl.creditPolicyNew(
    'description-only'
  )]: `Alltid avvis inaktive selskaper og konkurser`,
  [intl.creditPolicyNew('always-reject-inactive-companies')]: `Kom i gang`,
  [intl.creditPolicyNew('get-started-text')]: `Rediger retningslinjeinnstillinger`,
  [intl.creditPolicyNew('active-companies-only')]: `Kun aktive selskaper`,
  [intl.creditPolicyNew('save-changes')]: `Lagre endringer`,
  [intl.creditPolicyNew('inactive')]: `Inaktiv`,
  [intl.creditPolicyNew('active')]: `Aktiv`,
  [intl.creditPolicyNew('policy-published')]: `Publisert`,
  [intl.creditPolicyNew('policy-draft')]: `Utkast`,
  [intl.creditPolicyNew('save_modal_title')]: `Lagre endringer i kreditt policy din`,
  [intl.creditPolicyNew(
    'save_modal_text'
  )]: `Det ser ut som om du har gjort noen endringer i kreditt policy din. Er du sikker på at du vil forlate siden uten å lagre?`,
  [intl.creditPolicyNew('activation_modal_title')]: `Aktiver en policy`,
  [intl.creditPolicyNew(
    'activation_modal_text'
  )]: `Du er i ferd med å aktivere denne policyen. Reglene den inneholder vil nå informere om kredittråd, og denne endringen vil påvirke hele organisasjonen din. Er du sikker på at du vil fortsette?`,
  [intl.creditPolicyNew('deactivation_modal_title')]: `Deaktiver en policy`,
  [intl.creditPolicyNew(
    'deactivation_modal_text'
  )]: `Du er i ferd med å deaktivere denne policy. Reglene i policyen din vil ikke lenger gjelde, og denne endringen vil påvirke hele organisasjonen din. Er du sikker på at du vil fortsette?`,
  [intl.creditPolicyNew(
    'only-admin-users-can-see-this-feature'
  )]: `Bare administratorbrukere kan se denne funksjonen. Kontakt din kontoadministrator for mer informasjon.`,
  [intl.creditPolicyNew('choose-file')]: `Velg fil`,
  [intl.creditPolicyNew(
    'tips_status'
  )]: `Inaktive selskaper er selskaper som har opphørt, er oppløst, er under konkurs eller tvangsavvikling... Det anbefales å ekskludere slike selskaper fra policyen din for å sikre at du ikke gir dem kreditt...`,
  [intl.creditPolicyNew('select-action')]: `Velg handling`,
  [intl.creditPolicyNew('search-for-an-action')]: `Søk etter en handling`,
  [intl.creditPolicyNew(
    'are-you-sure-you-want-to-delete-this-policy'
  )]: `Er du sikker på at du vil slette policyen "{name}"?`,
  [intl.creditPolicyNew(
    'delete-policy-description'
  )]: `Reglene i disse retningslinjene vil bli fjernet permanent, og du vil slutte å motta varsler for selskapene de gjelder for.`,
  [intl.creditPolicyNew(
    'delete-policy-action'
  )]: `Skriv inn <b>{name}</b> for å bekrefte:`,
  [intl.creditPolicyNew('created-on')]: `Opprettet den`,
  [intl.creditPolicyNew('last-updated')]: `Sist oppdatert`,
  [intl.creditPolicyNew(
    'tolerance_input'
  )]: `{tolerance} {max, plural, =0 {{min}} =1 {{min} og {max}} other {{min} og {max}}}`,
  [intl.creditPolicyNew('industry_code_node_content')]: `Er {industryList}`,
  [intl.creditPolicyNew(
    'connected-bankruptcies-node-content'
  )]: `I den siste {category, plural, =0 {10} other {50}} år er {tolerance} {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} og {max}}}`,
  [intl.creditPolicyNew(
    'equity-node-content'
  )]: `Egenkapital {category, plural, =0 {inkludert} other {unntatt}} utbytte er {tolerance} {max, plural, =0 {{min}} =1 {{min} and {max}} other {{min} og {max}}}`,
  [intl.creditPolicyNew(
    'node-between-2-nodes-text-1'
  )]: `Du legger til en handling midt i en eksisterende serie med handlinger.`,
  [intl.creditPolicyNew(
    'node-between-2-nodes-text-2'
  )]: `Velg grenen som resten av handlingene skal fortsette på`,
  [intl.creditPolicyNew('node-between-2-nodes-option-yes')]: `"""Ja""-gren"`,
  [intl.creditPolicyNew('node-between-2-nodes-option-no')]: `"""Nei""-gren"`,
  [intl.creditPolicyNew('upgrade-advanced-fraud-indicators')]:
    'Oppgrader for å låse opp avanserte svindelindikatorer',
  [intl.creditPolicyNew('additional-fraud-variables')]:
    '12 ekstra svindelindikatorvariabler',
  [intl.creditPolicyNew('limit-fraud-risk')]: 'Begrens svindelrisikoen i din virksomhet',
  [intl.creditPolicyNew('monitor-fraud-indicators')]:
    'Overvåk svindelindikatorer på tvers av dine kunder',
  [intl.creditPolicyNew('talk-to-sales-rep')]:
    'Snakk med en salgsrepresentant for å diskutere dine behov og finne den beste løsningen for din virksomhet',
  [intl.creditPolicyNew('request-call-back')]: 'Be om tilbakeringing',
  [intl.creditPolicyNew('fraud-indicators-promotion')]:
    'Lås opp avanserte svindelindikatorer',

  [intl.customerManagement('add-new-users')]: `Legg til nye brukere`,
  [intl.customerManagement(
    'are-you-sure-dialog'
  )]: `Er du sikker på at du vil oppheve tilgangen for dette firmaet?`,
  [intl.customerManagement('customer-plan')]: `Kundeplan`,
  [intl.customerManagement('customer-plan-basic')]: `Basis`,
  [intl.customerManagement('customer-plan-enterprise')]: `Enterprise`,
  [intl.customerManagement('customer-plan-freemium')]: `Freemium`,
  [intl.customerManagement('customer-plan-pro')]: `Proff`,
  [intl.customerManagement('customer-plan-standard')]: `Standard`,
  [intl.customerManagement('customer-plan-trial')]: `Prøve`,
  [intl.customerManagement('customer-type')]: `Kundetype`,
  [intl.customerManagement('customer-type-customer')]: `Kunde`,
  [intl.customerManagement('customer-type-lost-customer')]: `Tapt kunde`,
  [intl.customerManagement('customer-type-lost-trial')]: `Tapt prøve`,
  [intl.customerManagement('customer-type-risika')]: `Risika (internt)`,
  [intl.customerManagement('customer-type-trial')]: `Prøve`,
  [intl.customerManagement('customer-updated')]: `Kunde oppdatert`,
  [intl.customerManagement('delete-company-dialog-title')]: `Slett kunde`,
  [intl.customerManagement('existing-customers')]: `Eksisterende kunder`,
  [intl.customerManagement('existing-users')]: `Eksisterende brukere`,
  [intl.customerManagement('expiry-date')]: `Utløpsdato`,
  [intl.customerManagement('handle-access')]: `Opphev tilgang`,
  [intl.customerManagement('manage-customer')]: `Administrer kunde`,
  [intl.customerManagement('my-customers')]: `Mine kunder`,
  [intl.customerManagement('new-customer')]: `Ny kunde`,
  [intl.customerManagement('new-customer-title')]: `Opprett ny kunde`,
  [intl.customerManagement('responsible-person')]: `Angi ansvarlig person`,
  [intl.customerManagement('revoke-access')]: `Oppheve tilgangen`,
  [intl.customerManagement('title')]: `Kundebehandling`,
  [intl.customerManagement('users')]: `Brukere`,
  [intl.developer(
    'confirm-remove-webhook-description'
  )]: `Pass på: Hvis du legger til en webhook igjen, vil en ny secret bli generert som betyr at du må oppdatere den.`,
  [intl.developer(
    'confirm-remove-webhook-title'
  )]: `Er du sikker på at du vil slette webhooken din?`,
  [intl.developer('documentation')]: `dokumentasjon`,
  [intl.developer('title')]: `Utvikler`,
  [intl.developer(
    'webhook-add-info'
  )]: `For mer informasjon om webhooks og hvordan du bruker dem`,
  [intl.developer(
    'webhook-existing-info'
  )]: `Webhooken din er konfigurert. Du kan lese mer om hvordan du bruker den fra vår`,
  [intl.developer('webhook-secret')]: `Webhook Secret`,
  [intl.developer('webhook-title')]: `Webhook`,
  [intl.developer('webhook-url')]: `Webhook URL`,
  [intl.developer('webhook-url-remove-button')]: `ReTerms og conditionsmove`,
  [intl.developer('webhook-url-save-button')]: `Legg til`,
  [intl.developer('webhook-url-test-button')]: `Test`,
  [intl.enforcedPasswordChange('confirm-password-label')]: `Bekreft passord`,
  [intl.enforcedPasswordChange(
    'description'
  )]: `Av sikkerhetsmessige årsaker må du endre passordet ditt.`,
  [intl.enforcedPasswordChange('min-characters')]: `Minimum 8 tegn`,
  [intl.enforcedPasswordChange('must-match-password')]: `Må matche passord`,
  [intl.enforcedPasswordChange(
    'password-change-success'
  )]: `Perfekt! Nå kan du fortsette å bruke Risika`,
  [intl.enforcedPasswordChange('password-label')]: `Passord`,
  [intl.enforcedPasswordChange('submit')]: `Endre passord`,
  [intl.enforcedPasswordChange('title')]: `Passordendring kreves`,
  [intl.exportButton(
    'description'
  )]: `{count, plural, =0 {Ingen eksport valgt} one {Eksporter størrelse på {count} rader} other {Eksporter størrelse på {count} rader}}`,
  [intl.exportButton(
    'max-export'
  )]: `{count, plural, other {Limited to the first {count}}}`,
  [intl.exportButton('max-out-of')]: `{count, plural, other {ut av {count} rader}}`,
  [intl.exportButton('text')]: `Eksport`,
  [intl.faq('Antal ansatte')]: `Antall ansatte`,
  [intl.faq('Connected bankruptcies')]: `Tilknyttede konkurser`,
  [intl.faq('Credit days')]: `Kredittdager`,
  [intl.faq('Credit maximum')]: `Maksimum kreditt`,
  [intl.faq('Distress percentage year')]: `Distress prosent 2020`,
  [intl.faq('Effect')]: `Effekt`,
  [intl.faq('Erklæringstype')]: `Erklæringstype`,
  [intl.faq('Explanation')]: `Forklaring`,
  [intl.faq('Financial key figures')]: `Finansielle nøkkeltall`,
  [intl.faq('Fraktil')]: `Persentil`,
  [intl.faq('How')]: `Hvordan`,
  [intl.faq('Key figure performance')]: `Nøkkeltall ytelse`,
  [intl.faq('Level of audit')]: `Revisjonsnivå`,
  [intl.faq('Maximum')]: `Maksimum`,
  [intl.faq('Number of employees')]: `Antall ansatte`,
  [intl.faq('Other Ratios')]: `Andre nøkkeltall`,
  [intl.faq('Ownership')]: `Eierskap`,
  [intl.faq('Performance')]: `Ytelse`,
  [intl.faq('Prevalence: 3.75%')]: `I 2019 gikk 2% av danske selskaper i distress.`,
  [intl.faq('Reason')]: `Årsaken`,
  [intl.faq('Risika score')]: `Risika score`,
  [intl.faq('Risk')]: `Risiko`,
  [intl.faq('Sole proprietorship model')]: `Enkeltpersonforetak modell`,
  [intl.faq('UC: 77.93%, Gini: 55.86%')]: `UC: 77.93%, Gini: 55.86%`,
  [intl.faq(
    'Understanding risika score and figures'
  )]: `Forståelse av risika score og tallene`,
  [intl.faq('Unscored companies')]: `Uscorede selskaper`,
  [intl.faq('Virksomhedsstørelse')]: `Selskapets størrelse`,
  [intl.faq('What')]: `Hva`,
  [intl.faq('Why')]: `Hvorfor`,
  [intl.faq(
    'With a decision threshold of 5 the model has a sensitivity of 81.01% and precision of 4.8%'
  )]: `Med en beslutningsterskel på 5 har modellen en følsomhet på 81.01% og presisjon of 4.8%`,
  [intl.faq(
    'With decision threshold on 5 the metrics is sensitivity: 81.01% and precision: 4.8%'
  )]: `Med en beslutningsterskel på 5 har modellen en følsomhet på 81.01% og presisjon of 4.8%`,
  [intl.faq('definition')]: `Definisjon`,
  [intl.faq('variable')]: `Variabel`,
  [intl.faq('find-answers')]: 'Finn et svar raskt',
  [intl.faq('add-company-monitoring-list')]: 'Legg til selskap i overvåkningslisten',
  [intl.faq('set-up-monitoring-emails')]: 'Sett opp overvåknings-e-poster',
  [intl.faq('adjust-credit-recommendation')]: 'Justér kredittanbefalingen',
  [intl.faq('view-companies-subsidiaries')]: 'Vis et selskaps datterselskaper',
  [intl.faq('chat-cta')]: 'Chat med oss',
  [intl.faq('welcome')]: 'Hei {name},',
  [intl.faq('response-time')]: 'Vi svarer vanligvis innen 10 min',
  [intl.faq('how-can-we-help')]: 'Hvordan kan vi hjelpe?',
  [intl.faq('prompt-desc')]:
    'Gi oss beskjed hvis du har noen spørsmål, vi er her for å hjelpe',
  [intl.financial('probability-of-closure')]: `Sannsynlighet for stenging`,
  [intl.financial('probability-of-distress')]: `Konkurssannsynlighet`,
  [intl.financial('distress')]: `Distress`,
  [intl.financialRatio('no-content')]: `Ingen innhold levert`,
  [intl.financialRatio('performance')]: `Ytelse`,
  [intl.firstTimeVisit('confirm-password-label')]: `Bekreft passord`,
  [intl.firstTimeVisit(
    'description'
  )]: `Sikkerhet først! Velg passord til plattformen for å komme i gang.`,
  [intl.firstTimeVisit('min-characters')]: `Minst 8 tegn`,
  [intl.firstTimeVisit('must-match-password')]: `Må matche passord`,
  [intl.firstTimeVisit('password-label')]: `Passord`,
  [intl.firstTimeVisit('submit')]: `Kom i gang`,
  [intl.firstTimeVisit('title')]: `Velg ditt passord`,
  [intl.generic('owners')]: `eiere`,
  [intl.generic('loading')]: `Laster`,
  [intl.generic('print')]: `Skrive ut`,
  [intl.firstTimeVisit('preferences')]: `Preferanser`,
  [intl.generic('actions')]: `Handlinger`,
  [intl.generic('active')]: `Aktiv`,
  [intl.generic('active-companies')]: 'Aktive selskaper',
  [intl.generic('add')]: `Legg til`,
  [intl.generic(
    'advanced-views-not-allowed'
  )]: `Avanserte visninger er ikke tilgjengelige med din nåværende plan. Klikk for å se mer!`,
  [intl.generic('all')]: `Alle`,
  [intl.generic('back')]: `Tilbake`,
  [intl.generic('cancel')]: `Avbryt`,
  [intl.generic('checks-left')]: `kredittsjekker igjen`,
  [intl.generic('clear')]: `Fjerne`,
  [intl.generic('clear-selection')]: `Klar`,
  [intl.generic('companies')]: `Selskaper`,
  [intl.generic('company')]: `Selskap`,
  [intl.generic('confirm-password')]: `Bekreft passord`,
  [intl.generic('create')]: `Opprett`,
  [intl.generic('cvr-number')]: `Organisasjonsnummer`,
  [intl.generic('denmark')]: `Danmark`,
  [intl.generic(
    'do-not-want-to-wait'
  )]: `Vil du ikke vente? Oppdater planen din nedenfor!`,
  [intl.generic('edit')]: `Rediger`,
  [intl.generic('emails')]: `E-poster`,
  [intl.generic('employees')]: `ansatte`,
  [intl.generic(
    'error-reach-us-on-chat'
  )]: `Noe gikk galt! Vennligst kontakt oss på chat`,
  [intl.generic(
    'failed-to-fetch-list'
  )]: `"""Noe gikk galt med å skaffe data for """"{name}""""!"""`,
  [intl.generic(
    'feature-not-available'
  )]: `Funksjon ikke lenger tilgjengelig i gjeldende plan`,
  [intl.generic('first-name')]: `Fornavn`,
  [intl.generic('first-page')]: `Første side`,
  [intl.generic('fte')]: `FTA (Fulltidsansatte)`,
  [intl.generic('get-intl.auditorInformation')]: `Få revisorinformasjon`,
  [intl.generic('get-credit-information')]: `Få kredittinformasjon`,
  [intl.generic('get-highlight-information')]: `Få informasjon om høydepunkter`,
  [intl.generic('import')]: `Importer`,
  [intl.generic('inactive')]: `Inaktiv`,
  [intl.generic('language')]: `Språk`,
  [intl.generic('last-name')]: `Etternavn`,
  [intl.generic('last-page')]: `Siste side`,
  [intl.generic('learn-more')]: `Les mer`,
  [intl.generic('missing-address')]: `Manglende adresse`,
  [intl.generic('monitor')]: `Overvåke`,
  [intl.generic('monitor-stop')]: `Stopp`,
  [intl.generic(
    'more-checks-on-monday'
  )]: `Du får {credit_rating_cap} flere kredittsjekker på mandag.`,
  [intl.generic('name')]: `Navn`,
  [intl.generic('never')]: `Ikke tilgjengelig`,
  [intl.generic('next-page')]: `Neste side`,
  [intl.generic('no')]: `Nei`,
  [intl.generic(
    'no-credit-information'
  )]: `Ingen tilgjengelig kredittinformasjon for dette selskapet!`,
  [intl.generic('no-data')]: `Ingen data`,
  [intl.generic('norway')]: `Norge`,
  [intl.generic('number-of-companies')]: `Antall selskaper`,
  [intl.generic('optional')]: `valgfri`,
  [intl.generic('password')]: `Passord`,
  [intl.generic('phone')]: `Telefon`,
  [intl.generic('previous-page')]: `Forrige side`,
  [intl.generic('remove')]: `Fjerne`,
  [intl.generic('restore-default')]: `Gjenopprett standard`,
  [intl.generic('roles')]: `Roller`,
  [intl.generic('rows')]: `Rader`,
  [intl.generic('save')]: `Lagre`,
  [intl.generic('selected')]: `Valgte`,
  [intl.generic('send')]: `Send`,
  [intl.generic('show-more')]: `Vis mer`,
  [intl.generic('stop')]: `Stopp`,
  [intl.generic('sweden')]: `Sverige`,
  [intl.generic('total')]: `Total`,
  [intl.generic('update')]: `Oppdater`,
  [intl.generic('yes')]: `Ja`,
  [intl.generic('you-have')]: `Du har`,
  [intl.generic('open-all')]: `Åpne alle`,
  [intl.generic('close-all')]: `Lukk alle`,
  [intl.generic('more-details')]: `Mer informasjon`,
  [intl.generic('less-details')]: `Mindre informasjon`,
  [intl.generic('days')]: `dager`,
  [intl.generic('unknown')]: `Ukjent`,
  [intl.generic('close')]: `Lukk`,
  [intl.generic('company-id')]: `Selskaps ID`,
  [intl.generic('company-name')]: `Selskapsnavn`,
  [intl.generic('company-type')]: `Selskapsform`,
  [intl.generic('date-of-incorporation')]: `Stiftelsesdato`,
  [intl.generic('email')]: `Email`,
  [intl.generic('status')]: `Status`,
  [intl.generic('risika-score')]: `Risika Score`,
  [intl.generic('credit-max')]: `Kredittgrense`,
  [intl.generic('risika-recommendation')]: `Risika-anbefaling`,
  [intl.generic('policy-outcome')]: `Utfall av policy`,
  [intl.generic('policy-credit-limit')]: `Politisk kredittgrense`,
  [intl.generic('street')]: `Gate`,
  [intl.generic('street-number')]: 'Gatenummer',
  [intl.generic('coname')]: 'Coname',
  [intl.generic('zip-code')]: 'Postnummer',
  [intl.generic('city')]: 'By',
  [intl.generic('municipality')]: 'Kommune',
  [intl.generic('postal-district')]: 'Postdistrikt',
  [intl.generic('country')]: 'Land',
  [intl.generic('number-of-employees')]: 'Antall ansatte',
  [intl.generic('industry-code')]: 'Industrikode',
  [intl.generic('industry-description')]: 'Industribeskrivelse',
  [intl.generic('internal-id')]: 'Industri ID',
  [intl.generic('currency')]: 'Valuta',
  [intl.generic('equity')]: 'Egenkapital',
  [intl.generic('profit-loss')]: 'Overskuddstap',
  [intl.generic('solidity')]: 'Soliditet',
  [intl.generic('liquidity')]: 'Likviditet',
  [intl.generic('unknown')]: 'Ukjent',
  [intl.generic('local-id')]: 'Lokal ID',
  [intl.generic('close')]: 'Lukk',
  [intl.generic('credit-score')]: 'Kreditscore',
  [intl.generic('clear')]: 'Fjerne',
  [intl.generic('English')]: 'Engelsk',
  [intl.generic('Danish')]: 'Dansk',
  [intl.generic('Norwegian')]: 'Norsk',
  [intl.generic('swedish')]: 'Svensk',
  [intl.generic('DENMARK')]: 'DANMARK',
  [intl.generic('SWEDEN')]: 'SVERIGE',
  [intl.generic('NORWAY')]: 'NORGE',
  [intl.country('fi')]: 'Finland',
  [intl.creditPolicy('fi')]: 'Finland',
  [intl.generic('finland')]: 'Finland',
  [intl.generic('FINLAND')]: 'FINLAND',
  [intl.riskMonitoringOverview('fi_FI')]: 'Finland',
  [intl.generic('save_changes')]: 'Lagre endringer',
  [intl.generic('discard')]: 'Forkast',
  [intl.generic('successful_changes')]: 'Endringene dine er lagret vellykket',
  [intl.generic('continue')]: 'Fortsett',
  [intl.generic('cancel')]: 'Avbryt',
  [intl.generic('view-less')]: 'Se mindre',
  [intl.generic('view-all')]: 'Se alle',
  [intl.generic('x-items-selected')]:
    '{count, plural, =0 {} one {{count} element valgt} other {{count} elementer valgt}}',
  [intl.grading('average')]: 'Gjennomsnittlig',
  [intl.grading('not-available')]: 'n/a',
  [intl.grading('strong')]: 'Sterk',

  [intl.home('no-searches-message')]:
    'Har du ikke søkt etter noe ennå? Begynn med å klikke på søkefeltet ovenfor.',

  [intl.grading('very-strong')]: 'Veldig sterk',
  [intl.grading('very-weak')]: 'Veldig svak',

  [intl.home('monitor-recent-changes')]: 'Nylig sett',
  [intl.home('recently-viewed')]: 'Nylig sett',

  [intl.grading('weak')]: 'Svak',
  [intl.history('advancedfilters')]: 'Filtrering',
  [intl.history('companyhierarchy')]: 'Selskapshierarki',
  [intl.history('creditpolicy')]: 'Kredittpolitikk',
  [intl.history('home')]: 'Hjem',
  [intl.history('monitoring')]: 'Risikoovervåking',
  [intl.history('company-comparison')]: 'Sammenligne',
  [intl.history('advance-credit-policy')]: 'Advance Credit Policy',
  [intl.history('observational-lists')]: 'Risk Insights',
  [intl.home('faq-score-calculation-description')]:
    'Vi beregner Risk Score ved å bruke en målekortmodell, som deler inn alle selskaper i risikogruppe 1 - 10 basert på et bransjejustert økonomisk nøkkeltall, selskapets alder, type erklæring, relaterte konkurser, tvangsoppløsninger og bransjen selskapet er. i. inkludert i modellen er årets resultat, EBITDA, EBIT, finanskostnader, omløpsmidler, balanse, gjeld, egenkapital, valuta, selskapsform, stiftelsesdato, type erklæring og bransje.',
  [intl.home('faq-score-calculation-title')]: 'Hvordan beregnes Risika Score?',
  [intl.home('faq-title')]: 'Ofte spurte spørsmål (FAQ)',
  [intl.home('recent-searches-content')]:
    'Her får du rask tilgang til de selskapene og personene du nylig har søkt etter.',
  [intl.home('integrations-description')]:
    'Få oversikt over hvordan du kan automatisere kredittprosessen i bedriften med en integrasjon til dine eksisterende CRM- og ERP-systemer.',

  [intl.home('searches-this-month')]: 'Dine søk denne måneden',

  [intl.home('integrations-title')]: 'Integrer Risika i dine eksisterende systemer',
  [intl.home('monitor-list-label')]: 'Dine overvåkingslister',
  [intl.home('monitor-overview-title')]: 'Dine overvåkede selskaper',
  [intl.home('monitor-recent-changes')]: 'Nylige endringer',
  [intl.home('no-changes')]: 'Ingen endringer',
  [intl.home('sales-and-marketing-description')]:
    'Få informasjon om hvordan du kan målrette markedsføring med lead-lister og få mer innsikt i det første møtet med en potensiell kunde.',
  [intl.home('sales-and-marketing-title')]:
    'Bruk Risika i salgs- eller markedsavdelingen',
  [intl.home('newsletter-description')]:
    'Den nye scorecardmodel er live på Risika-dashbordet. Mange bedrifter i Danmark og Norge får ny og mer presis Risika score. Du kan lese mer om den nye scorecardmodel her. Du er alltid velkommen til å kontakte oss hvis du har spørsmål.',
  [intl.home('newsletter-description-ecp')]:
    'Ønsker du at automatisere dine kreditbeslutninger? Se vores on-demand webinar, hvor Jamie Reynolds & Kristoffer Gerdes introducerer vores nyeste produkt Credit Automator.',
  [intl.home('newsletter-title-ecp')]: 'Credit Automator On-Demand Webinar',
  [intl.home('newsletter-title')]: 'Credit Automator On-Demand Webinar',
  [intl.home('subtitle')]: 'Lær hvordan du får mest mulig ut av plattformen.',
  [intl.home('title')]: 'Velkommen til Risika',

  [intl.homePage('contact-availability')]: 'Tilgjengelig de neste 3 ukene',
  [intl.homePage('contact-title')]: 'Har du spørsmål?',
  [intl.homePage('go-to-monitoring')]: 'Gå til overvåking',
  [intl.homePage('invite-button')]: 'Inviter',
  [intl.homePage('invite-description')]:
    'Hvis du inviterer en forretningspartner og de prøver systemet innen en uke',
  [intl.homePage('invite-description-fields')]: '"Fyll ut skjemaet nedenfor',
  [intl.homePage('invite-form-company')]:
    'Søk etter selskap eller skriv inn Organisasjonsnummer',
  [intl.homePage('invite-form-email')]: 'Jobb e-post',
  [intl.homePage('invite-form-invite-button')]: 'Send invitasjon',
  [intl.homePage('invite-form-name')]: 'Fullt navn',
  [intl.homePage('invite-form-no-options')]: 'Ingen selskaper',
  [intl.homePage('invite-sent-success')]: 'Takk! Vi sender en invitasjon innen 24 timer',
  [intl.homePage('invite-suplementary-text')]: 'Kjenner du noen som vil prøve Risika?',
  [intl.homePage('invite-title')]:
    'Inviter en forretningspartner og få billetter til Tivoli',
  [intl.homePage('monitor-changes-subtitle')]: 'Endringer de siste 7 dagene',
  [intl.homePage('monitor-changes-title')]: 'Mest relevante endringer',
  [intl.homePage('monitor-overview-empty')]: 'Ingen overvåket selskaper',
  [intl.homePage('monitor-overview-import')]:
    'Du kan importere selskaper for å komme i gang',
  [intl.homePage('monitor-portfolio-subtitle')]:
    '{count, plural, =0 {Du kan ikke overvåke flere selskaper} one {Du kan fortsatt overvåke ett selskap til} other {Du kan fortsatt overvåke {count} selskaper til}}',
  [intl.homePage('monitor-portfolio-title')]: 'Din overvåkingsportefølje',
  [intl.homePage('monitor-suggestions-empty')]: 'Ingen forslag ennå',
  [intl.homePage('monitor-suggestions-empty-description')]:
    'Du får forslag basert på dine søk',
  [intl.homePage('monitor-suggestions-subtitle')]: 'Basert på dine siste søk',
  [intl.homePage('monitor-suggestions-title')]: 'Overvåk forslag',
  [intl.homePage('news-letter-april-main')]: 'Risikas apriloppdatering',
  [intl.homePage('news-letter-april-secondary')]:
    'Les vårt siste nyhetsbrev med nye forbedringer',
  [intl.homePage('news-letter-february-main')]: 'Risikas februaroppdatering',
  [intl.homePage('news-letter-february-secondary')]:
    'Les vårt siste nyhetsbrev med nye forbedringer',
  [intl.homePage('news-letter-march-main')]: 'Risikas marsoppdatering',
  [intl.homePage('news-letter-march-secondary')]:
    'Les vårt siste nyhetsbrev med nye forbedringer',
  [intl.homePage('recent-searches-subtitle')]: 'Basert på din siste aktivitet',
  [intl.homePage('recent-searches-title')]: 'Siste søk',
  [intl.homePage('relevant-changes-empty')]: 'Ingen endringer de siste 7 dagene',
  [intl.homePage('relevant-changes-empty-description')]: '"Så snart det er endringer',
  [intl.homePage('resources-and-integrations-title')]: 'Lær mer om Risika',
  [intl.homePage('resources-api-solution')]: 'API-løsning',
  [intl.homePage('resources-api-solution-description')]: 'Les mer om API-løsningen vår',
  [intl.homePage('resources-click-copy-example')]:
    'Klikk for å kopiere på kredittsjekk-siden',
  [intl.homePage('resources-click-copy-example-description')]:
    'Enkelt kopiere data for et selskap',
  [intl.homePage('resources-dynamics-crm')]: 'Dynamics CRM',
  [intl.homePage('resources-dynamics-crm-description')]:
    'Les om vår integrasjon med Microsoft Dynamics',
  [intl.homePage('resources-features-and-integrations')]: 'Funksjoner og integrasjoner',
  [intl.homePage('resources-features-and-integrations-description')]:
    'Les om funksjonene og integrasjonene våre',
  [intl.homePage('resources-monitoring-delete-example')]:
    'Fjern flere selskaper fra risikoovervåking samtidig',
  [intl.homePage('resources-monitoring-delete-example-description')]:
    'Demo om fjerning av flere selskaper i risikoovervåking',
  [intl.homePage('resources-monitoring-example')]:
    'Risikoovervåking - hvordan fungerer det?',
  [intl.homePage('resources-monitoring-example-desription')]:
    'Demo om hvordan risikoovervåkingen fungerer',
  [intl.homePage('resources-monitoring-import-example')]: 'Importer risikoovervåking',
  [intl.homePage('resources-monitoring-import-example-description')]:
    'Demo av importerende selskaper i risikoovervåking',
  [intl.homePage('resources-navision')]:
    'Les mer om vår integrasjon med Microsoft Navision',
  [intl.homePage('resources-navision-description')]:
    'Les om vår integrasjon med Microsoft Navision',
  [intl.homePage('resources-search-example')]: 'Søker etter selskaper',
  [intl.homePage('resources-search-example-description')]:
    'Demo om hvordan du kan søke etter selskaper',
  [intl.homePage('welcome')]: 'Velkommen {name}',
  [intl.homePage('all-lists')]: 'Alle lister',
  [intl.homePage('portfolio-recent-changes')]: 'Porteføljens siste endringer',
  [intl.homePage('no-data')]: 'Ingen data å vise for denne listen',
  [intl.importButton('description')]:
    '"CSV og Excel (XLSX) format støttes. Kolonnen som inneholder Organisasjonsnummer må merkes ""Organisasjonsnummer"""',
  [intl.importButton('text')]: 'Importer',
  [intl.keyFinancialFigures('export-excel')]: 'Eksporter Excel',
  [intl.keyFinancialFigures('original-currency')]: 'Opprinnelig valuta',
  [intl.keyFinancialFigures('selected-currency')]: 'Valgt valuta',
  [intl.keyFinancialFigures('export-pdf')]: 'Eksporter PDF',
  [intl.keyFinancialFigures('EBIT')]: 'EBIT',
  [intl.keyFinancialFigures('EBITDA')]: 'EBITDA',
  [intl.keyFinancialFigures('annual-report-button')]: 'Årsregnskap',
  [intl.keyFinancialFigures('assets')]: 'Sum eiendeler',
  [intl.keyFinancialFigures('balance')]: 'Sum egenkapital og gjeld',
  [intl.keyFinancialFigures('basic-view')]: 'Minimer tabell',
  [intl.keyFinancialFigures('cash')]: 'Bankinnskudd, kontanter og lignende',
  [intl.keyFinancialFigures('consolidated-statement')]: 'Konsolidert regnskap',
  [intl.keyFinancialFigures('contributedCapital')]: 'Aksjekapital',
  [intl.keyFinancialFigures('current-assets')]: 'Omløpsmidler',
  [intl.keyFinancialFigures('currentAssets')]: 'Omløpsmidler',
  [intl.keyFinancialFigures('currentFinancialAssets')]: 'Finansielle omløpsmidler',
  [intl.keyFinancialFigures('current_financial_assets')]: 'Finansielle omløpsmidler',
  [intl.keyFinancialFigures('debt')]: 'Sum gjeld',
  [intl.keyFinancialFigures('depreciation')]: 'Avskrivninger',
  [intl.keyFinancialFigures('detailed-view')]: 'Detaljert visning',
  [intl.keyFinancialFigures('dividend')]: 'Utbytte',
  [intl.keyFinancialFigures('ebit')]: 'EBIT',
  [intl.keyFinancialFigures('ebitda')]: 'EBITDA',
  [intl.keyFinancialFigures('fixed-assets')]: 'Anleggsmidler',
  [intl.keyFinancialFigures('full-view')]: 'Vis hele tabellen',
  [intl.keyFinancialFigures('gross-result')]: 'Bruttofortjeneste',
  [intl.keyFinancialFigures('shortTermReceivablesFromGroupEnterprises')]:
    'Kortsiktige fordringer på selskap i samme konsern',
  [intl.keyFinancialFigures('shortTermDebtToGroupEnterprises')]:
    'Kortsiktig gjeld til selskap i samme konsern',
  [intl.keyFinancialFigures('grossResult')]: 'Bruttofortjeneste',
  [intl.keyFinancialFigures('intangible-assets')]: 'Immaterielle eiendeler',
  [intl.keyFinancialFigures('intangibleAssets')]: 'Immaterielle eiendeler',
  [intl.keyFinancialFigures('interestExpenses')]: 'Finanskostnader',
  [intl.keyFinancialFigures('interestIncome')]: 'Finansinntekter',
  [intl.keyFinancialFigures('interest_expenses')]: 'Finanskostnader',
  [intl.keyFinancialFigures('interest_income')]: 'Finansinntekter',
  [intl.keyFinancialFigures('inventories')]: 'Varelager',
  [intl.keyFinancialFigures('liabilitiesAndEquity')]: 'Gjeld og egenkapital',
  [intl.keyFinancialFigures('long-term-debt')]: 'Langsiktig gjeld',
  [intl.keyFinancialFigures('longTermDebt')]: 'Langsiktig gjeld',
  [intl.keyFinancialFigures('noncurrentAssets')]: 'Anleggsmidler',
  [intl.keyFinancialFigures('noncurrentFinancialAssets')]:
    'Langsiktige finansielle eiendeler',
  [intl.keyFinancialFigures('noncurrent_financial_assets')]:
    'Langsiktige finansielle eiendeler',
  [intl.keyFinancialFigures('not-available')]: 'Ikke tilgjengelig',
  [intl.keyFinancialFigures('ordinaryProfit')]: 'Ordinært resultat',
  [intl.keyFinancialFigures('ordinary_profit')]: 'Ordinært resultat',
  [intl.keyFinancialFigures('otherNetFinancialIncome')]: 'Annen netto finansinntekter',
  [intl.keyFinancialFigures('otherShortTermDebt')]: 'Annen kortsiktig gjeld',
  [intl.keyFinancialFigures('otherShortTermReceivables')]: 'Andre kortsiktige fordringer',
  [intl.keyFinancialFigures('other_net_financial_income')]: 'Annen netto finansinntekter',
  [intl.keyFinancialFigures('other_short_term_debt')]: 'Annen kortsiktig gjeld',
  [intl.keyFinancialFigures('parent-statement')]: 'Regnskap for morselskap',
  [intl.keyFinancialFigures('profit-loss')]: 'Årsresultat',
  [intl.keyFinancialFigures('profitLoss')]: 'Årsresultat',
  [intl.keyFinancialFigures('property-plant-and-equipment')]: 'Varige driftsmidler',
  [intl.keyFinancialFigures('propertyPlantAndEquipment')]: 'Varige driftsmidler',
  [intl.keyFinancialFigures('provisions')]: 'Avsetninger for forpliktelser',
  [intl.keyFinancialFigures('reserves')]: 'Reserver',
  [intl.keyFinancialFigures('retained-earnings')]: 'Opptjent egenkapital',
  [intl.keyFinancialFigures('retainedEarnings')]: 'Opptjent egenkapital',
  [intl.keyFinancialFigures('revenue')]: 'Inntekter',
  [intl.keyFinancialFigures('share-capital')]: 'Aksjekapital',
  [intl.keyFinancialFigures('short-term-debt')]: 'Kortsiktig gjeld',
  [intl.keyFinancialFigures('shortTermDebt')]: 'Kortsiktig gjeld',
  [intl.keyFinancialFigures('shortTermDebtToBanks')]:
    'Kortsiktig gjeld til finansinstitusjoner',
  [intl.keyFinancialFigures('shortTermDebtToGroupEnterprises')]:
    'Kortsiktig gjeld til selskap i samme konsern',
  [intl.keyFinancialFigures('shortTermReceivablesFromGroupEnterprises')]:
    'Kortsiktige fordringer på selskap i samme konsern',
  [intl.keyFinancialFigures('shortTermTaxPayables')]: 'Betalbar skatt',
  [intl.keyFinancialFigures('shortTermTradePayables')]: 'Leverandørgjeld',
  [intl.keyFinancialFigures('short_term_debt_to_banks')]:
    'Kortsiktig gjeld til finansinstitusjoner',
  [intl.keyFinancialFigures('short_term_receivables_from_sales_and_services')]:
    'Fordringer på salg og tjenester',
  [intl.keyFinancialFigures('short_term_tax_payables')]: 'Kortsiktig gjeld',
  [intl.keyFinancialFigures('short_term_trade_payables')]: 'Leverandørgjeld',
  [intl.keyFinancialFigures('staffExpenses')]: 'Personalkostnader',
  [intl.keyFinancialFigures('staff_expenses')]: 'Personalkostnader',
  [intl.keyFinancialFigures('taxExpenses')]: 'Skattekostnad',
  [intl.keyFinancialFigures('tax_expenses')]: 'Skattekostnad',
  [intl.keyFinancialFigures('title')]: 'Finansielle nøkkeltall',
  [intl.keyFinancialFigures('total-debt')]: 'Sum gjeld',
  [intl.keyFinancialFigures('currency')]: 'Valuta',
  [intl.keyFinancialFigures('equity')]: 'Egenkapital',
  [intl.keyFinancialFigures('profit-loss')]: 'Årsresultat',
  [intl.keyFinancialFigures('solidity')]: 'Soliditet',
  [intl.keyFinancialFigures('liquidity')]: 'Likviditet',
  [intl.keyFinancialFigures('capital')]: 'Kapital',
  [intl.keyFinancialFigures('shortTermReceivablesFromSalesAndServices')]:
    'Fordringer på salg og tjenester',
  [intl.keyFinancialFigures('start-date')]: 'Rapportens startdato',
  [intl.keyFinancialFigures('end-date')]: 'Rapportens sluttdato',
  [intl.loginRegister('forgot-your-password')]: 'Glemt ditt passord',
  [intl.loginRegister('login-submit-button')]: 'Logg inn',
  [intl.loginRegister('login-to-account')]: 'Logg inn på kontoen din',
  [intl.loginRegister('register-create-account')]: 'Opprett konto',
  [intl.loginRegister('register-create-button')]: 'Opprett',
  [intl.loginRegister('register-popup-failure')]:
    'Opprettelsen av kontoen mislyktes! Kontakt systemadministratoren',
  [intl.loginRegister('register-popup-password')]: 'Passordene er ikke like',
  [intl.loginRegister('register-popup-success')]:
    'Kontoen ble opprettet! Viderekobler til innloggingssiden',
  [intl.loginRegister('remember-me')]: 'Husk meg',
  [intl.loginRegister('static-content-paragraph-1')]:
    'Vår visjon er å levere den beste finansielle løsningen for risikostyring til våre kunder globalt.',
  [intl.loginRegister('static-content-paragraph-2')]:
    'For å oppnå denne visjonen trekker vi ut verdifull innsikt fra både ikke-tradisjonelle og offentlige datakilder ved hjelp av avanserte algoritmer. Sluttmålet kan tilpasses',
  [intl.loginRegister('static-content-welcome')]: 'Velkommen til Risika!',

  [intl.mainRecommendation('auditor_selected')]: 'Revisor valgt',
  [intl.mainRecommendation('company-id')]: 'Lokal selskaps-ID',
  [intl.mainRecommendation('company-id-dk')]: 'Lokal selskaps-ID (CVR)',
  [intl.mainRecommendation('company-id-no')]: 'Lokal selskaps-ID (Orgnr.)',
  [intl.mainRecommendation('company-id-se')]: 'Lokal selskaps-ID (Org.nr)',
  [intl.mainRecommendation('copy_company_address')]:
    'Selskapets adresse kopiert til utklippstavlen',
  [intl.mainRecommendation('copy_company_local_id')]:
    'Selskapet lokale id kopiert til utklippstavlen',
  [intl.mainRecommendation('copy_company_name')]:
    'Selskapsnavn kopiert til utklippstavlen',
  [intl.mainRecommendation('currency')]: 'Oppført i ',
  [intl.mainRecommendation('edit-view')]: 'Rediger visning',
  [intl.mainRecommendation('get-credit-information')]: 'Få kredittinformasjon',
  [intl.mainRecommendation('latest-annual-report')]: 'Siste årsrapport',
  [intl.mainRecommendation('performance')]: 'Historisk utvikling',
  [intl.mainRecommendation('recommendation-credit')]: 'Ingen kreditt',
  [intl.mainRecommendation('recommendation-credit-unknown')]: 'Ukjent',
  [intl.mainRecommendation('recommended-credit-description')]: 'Anbefalt kredittgrense',
  [intl.mainRecommendation('recommended-days')]:
    '{amount, plural, =0 {Ingen kredittdager} one {Maks {amount} dag} other {Maks {amount} dager}}',
  [intl.mainRecommendation('recommended-days-description')]:
    'Anbefalte dager med kreditt',
  [intl.mainRecommendation('risika-estimation')]: 'Risikas vurdering',
  [intl.mainRecommendation('risk-level')]: 'Risikonivå',
  [intl.mainRecommendation('risk-level-high')]: 'Høy Risiko',
  [intl.mainRecommendation('risk-level-high-short')]: 'Høy',
  [intl.mainRecommendation('risk-level-low')]: 'Lav Risiko',
  [intl.mainRecommendation('risk-level-low-short')]: 'Lav',
  [intl.mainRecommendation('risk-level-medium')]: 'Middels Risiko',
  [intl.mainRecommendation('risk-level-medium-short')]: 'Medium',
  [intl.mainRecommendation('risk-level-medium-short')]: 'Medium',
  [intl.mainRecommendation('risk-level-sole-proprietorship')]: 'Enkeltpersonforetak',
  [intl.mainRecommendation('risk-level-unknown')]: 'Ukjent risiko',
  [intl.mainRecommendation('statstidende-header')]: 'Konkursvarsel',
  [intl.mainRecommendation('statstidende-title')]: 'Gazette ',
  [intl.mainRecommendation('date')]: 'Dato: ',
  [intl.mainRecommendation('case-id')]: 'Sagsnummer: ',
  [intl.mainRecommendation('statstidende-link')]: 'Gazette link: ',
  [intl.mainRecommendation('status')]: 'Status: ',
  [intl.mainRecommendation('status-active-from')]: 'Statusendring fra: ',
  [intl.mainRecommendation('you-have')]: 'Du har',
  [intl.mainRecommendation('explanation')]: 'Forklaring',

  [intl.mainRecommendation('estimation')]: 'Risikas vurdering',
  [intl.mainRecommendation('norwegian-payment-remarks')]: 'Betalingsanmærkninger',
  [intl.mainRecommendation('credit-override')]: 'Policy override',
  [intl.mainRecommendation('company-highlights')]: 'Selskapets Høydepunkter',
  [intl.mainRecommendation('risika-score-calculation')]: 'Risika score utregning',

  [intl.mainRecommendation('recommendation')]: 'Anbefaling',
  [intl.mainRecommendation('credit-max')]: 'Kredittgrense',
  [intl.mainRecommendation('credit-days')]: 'Dager med kreditt',
  [intl.mainRecommendation('credit-policy')]: 'Kredittpolitikk',
  [intl.mainRecommendation('date')]: 'Dato: ',
  [intl.mainRecommendation('case-id')]: 'Sagsnummer: ',
  [intl.mainRecommendation('statstidende-link')]: 'Link: ',
  [intl.mainRecommendation('bankruptcy-noticed')]: 'Konkursvarsel',
  [intl.mainRecommendation('status-label')]: 'Status: ',
  [intl.mainRecommendation('valid-from')]: 'Statusendring fra: ',
  [intl.mainRecommendation('undergoing-bankruptcy')]: 'Under konkursbehandling',
  [intl.mainRecommendation('performance-no-data')]:
    'Ytelseshistorikkdata til dette selskapet er ikke tilgjengelig',
  [intl.paymentRemarks('widget-title')]: 'Betalingsanmærkninger',
  [intl.paymentRemarks('widget-text')]:
    '{count, plural, one {Det er {count} registrert betalingsanmerkning på denne virksomheten.} other {Det er {count} registrerte betalingsanmerkninger på denne virksomheten.}}',
  [intl.paymentRemarks('widget-open-modal')]: 'Se betalings\u00ADanmerkninger',
  [intl.paymentRemarks('creditor')]: 'Kreditorer',
  [intl.paymentRemarks('type')]: 'Anmerkningstype',
  [intl.paymentRemarks('case-id')]: 'Saksnummer',
  [intl.paymentRemarks('amount')]: 'Beløb',
  [intl.paymentRemarks('registered')]: 'Registrert',
  [intl.paymentRemarks('status')]: 'Status',
  [intl.paymentRemarks('data-source')]: 'Datakilde',
  [intl.paymentRemarks('total-remarks')]:
    '{count, plural, one {{count} betalingsanmerkning samlet} other {{count} betalingsanmerkninger samlet}}',
  [intl.paymentRemarks('asterisk-1')]:
    '* Skylder ukjent beløp, sakens valute er ikke i NOK',
  [intl.paymentRemarks('asterisk-2')]:
    '** Ved sammenslåing av to selskaper, vil saker fra selskapet som er oppløst etter en fusjon, bli opprettet med et nytt organisasjonsnummer.',
  [intl.paymentRemarks('legal-collector')]:
    'Betalingsanmerkninger innsamles fra Intrum, Visma og Kredinor og er gyldige til bruk i kredittrapportering i Norge.I følge norsk lov kan betalingsanmerkninger oppbevares i fire år, deretter blir de slettet fra våres system.',
  [intl.paymentRemarks('total-unsettled-amount')]: 'Samlet ubetalt beløp',
  [intl.paymentRemarks('total-unsettled-number')]: 'Totalt antall ubetalte poster',
  [intl.paymentRemarks('debt-collection-agencies')]: 'Inkassobyråer',
  [intl.paymentRemarks('data-from')]: 'Data fra',
  [intl.paymentRemarks('unique-creditors')]: 'Unike kreditorer',
  [intl.paymentRemarks('avg-unsettled-time')]: 'Gjennomsnittlig gjeldsperiode',
  [intl.paymentRemarks('active-creditors')]: 'aktive kreditorer',

  [intl.newScorecard('title')]: 'Risika score utregning',
  [intl.newScorecard('title-tooltip')]:
    'Risika score modellen velger 25 ut av 180 tilgjengelige faktorer som best kan forutsi konkurs. Hver faktor blir tildelt en vekt, basert på faktorens verdi, som samlet gir den endelige scoren.',
  [intl.newScorecard('high_positive_impact')]: 'Stor positiv innflytelse',
  [intl.newScorecard('moderate_positive_impact')]: 'Moderat positiv innflytelse',
  [intl.newScorecard('minor_impact')]: 'Mindre innflytelse',
  [intl.newScorecard('moderate_negative_impact')]: 'Moderat negativ innflytelse',
  [intl.newScorecard('high_negative_impact')]: 'Stor negativ innflytelse',
  [intl.newScorecard('factor-counter')]:
    '{count, plural, =0 {{count} faktorer} one {{count} faktor} other {{count} faktorer}}',
  [intl.newScorecard('minor-impact-tooltip')]:
    'Disse faktorene har liten til ingen påvirkning på scoren. Dette betyr at de ikke påvirket scoren særlig i hverken positiv eller negativ retning',

  [intl.monitorFrequency('daily')]: 'Hver dag',
  [intl.monitorFrequency('daily-select')]: 'Daglig',
  [intl.monitorFrequency('friday-select')]: 'Fredag',
  [intl.monitorFrequency('how-often-label')]: 'Hvor ofte?',
  [intl.monitorFrequency('monday-select')]: 'Mandag',
  [intl.monitorFrequency('monthly')]: 'Hver måned',
  [intl.monitorFrequency('monthly-select')]: 'Månedlig (første mandag)',
  [intl.monitorFrequency('saturday-select')]: 'Lørdag',
  [intl.monitorFrequency('sunday-select')]: 'Søndag',
  [intl.monitorFrequency('thursday-select')]: 'Torsdag',
  [intl.monitorFrequency('title')]: 'Hvor ofte vil du bli varslet?',
  [intl.monitorFrequency('tuesday-select')]: 'Tirsdag',
  [intl.monitorFrequency('wednesday-select')]: 'Onsdag',
  [intl.monitorFrequency('weekly-friday')]: 'Hver Fredag',
  [intl.monitorFrequency('weekly-monday')]: 'Hver Mandag',
  [intl.monitorFrequency('weekly-saturday')]: 'Hver Lørdag',
  [intl.monitorFrequency('weekly-select')]: 'Ukentlig',
  [intl.monitorFrequency('weekly-sunday')]: 'Hver Søndag',
  [intl.monitorFrequency('weekly-thursday')]: 'Hver Torsdag',
  [intl.monitorFrequency('weekly-tuesday')]: 'Hver Tirsdag',
  [intl.monitorFrequency('weekly-wednesday')]: 'Hver Onsdag',
  [intl.monitorFrequency('which-day-label')]: 'Hvilken dag?',

  [intl.navigation('advanced-filters')]: 'Filtrering',
  [intl.navigation('company-comparison')]: 'Sammenligne',
  [intl.navigation('cfo-view')]: 'CFO Visning',
  [intl.navigation('chat')]: 'Chat',
  [intl.navigation('company-hierarchy')]: 'Selskapshierarki',
  [intl.navigation('credit-check')]: 'Kredittsjekk',
  [intl.navigation('credit-policy')]: 'Kredittpolitikk',
  [intl.navigation('fraud-indicators')]: 'Svindelindikatorer',
  [intl.navigation('customer-management')]: 'Kunder',
  [intl.navigation('dashboard')]: 'Kontollpanel',
  [intl.navigation('developer')]: 'Utvikler',
  [intl.navigation('faq')]: 'FAQ',
  [intl.navigation('home')]: 'Hjem',
  [intl.navigation('login')]: 'Logg inn',
  [intl.navigation('logout')]: 'Logg ut',
  [intl.navigation('relation-overview')]: 'Personer',
  [intl.creditPolicyNew('people')]: 'Personer',
  [intl.navigation('risk-monitoring')]: 'Overvåking',
  [intl.navigation('enterprise-credit-policy')]: 'Credit Automator',
  [intl.navigation('risk-monitoring-new')]: 'Ny Risikoovervåking',
  [intl.navigation('search-business')]: 'Søk',
  [intl.navigation('settings')]: 'Innstillinger',
  [intl.navigation('user-management')]: 'Brukeradministrasjon',
  [intl.navigation('revoked-warning')]: 'Du har ikke tilgang',
  [intl.navigationTooltip('company-hierarchy')]:
    'Søk etter et selskap med datterselskaper',
  [intl.navigationTooltip('credit-check')]: 'Søk etter et selskap først',
  [intl.navigationTooltip('relation-overview')]: 'Søk etter person først',
  [intl.news('no-articles')]: 'Ingen artikler for øyeblikket',
  [intl.news('show-less')]: 'Mindre',
  [intl.news('show-more')]: 'Mer',
  [intl.news('title')]: 'Nyheter',

  [intl.notFound('best-experience')]: 'For den beste opplevelsen',
  [intl.notFound('better-performance')]: 'Bedre ytelse',
  [intl.notFound('not-found-402')]: 'Hvis du er på prøve',
  [intl.notFound('not-found-404')]: 'Dessverre',
  [intl.notFound('not-found-500')]:
    'Vi har noen tekniske problemer for øyeblikket. Vi kommer snart!',
  [intl.notFound('not-found-503')]:
    'Noe gikk galt med forespørselen din. Det er mulig at serverne våre er nede for vedlikehold eller er overbelastet. Prøv igjen om noen minutter.',
  [intl.notFound('not-found-button-dashboard')]: '"Gå til ""Kontollpanel"""',
  [intl.notFound('not-found-button-risika-dk')]: '"Gå til ""Risika.dk/no"""',
  [intl.notFound('not-supported-header')]: 'Ikke støttet',
  [intl.notFound('not-supported-text')]:
    'For å sikre en best mulig opplevelse for våre brukere',
  [intl.notFound('pricing-button')]: '"Gå til ""Planer og Priser"""',
  [intl.notFound('under-meintenance-header')]: 'Under vedlikehold',
  [intl.notFound('under-meintenance-text')]:
    'Risika kontrollpanel og API er for tiden under vedlikehold. Vi er snart tilbake!',

  [intl.popconfirm('cancel')]: 'Avbryt',
  [intl.popconfirm('confirm')]: 'Ok',
  [intl.popconfirm('title')]: 'Er du sikker?',

  [intl.relationOverview('end-date')]: 'Sluttdato',
  [intl.relationOverview('function')]: 'Funksjon',
  [intl.relationOverview('function-status')]: 'Funksjonsstatus',
  [intl.relationOverview('no-entity-redirect')]:
    'Ingen selskaper eller enkeltpersoner valgt',
  [intl.relationOverview('start-date')]: 'Startdato',
  [intl.relationOverview('title')]: 'Relasjonsoversikt',

  [intl.relations('auditor')]: 'Revisor',
  [intl.relations('board_member')]: 'Styremedlem',
  [intl.relations('branch_manager')]: 'Avdelingsleder',
  [intl.relations('ceo')]: 'CEO',
  [intl.relations('founder')]: 'Grunnlegger',
  [intl.relations('legal_owner')]: 'Juridisk eier',
  [intl.relations('management')]: 'Ledelsen',
  [intl.relations('president')]: 'Styreleder',
  [intl.relations('real_owner')]: 'Virkelig rettighetshaver',
  [intl.relations('stakeholder')]: 'Interessent',
  [intl.relations('substitute')]: 'Varamedlem',
  [intl.relations('vice_president')]: 'Nestleder',

  [intl.relevantFinancialRatios('asset_turnover')]: 'Kapitalens omløpshastighet',
  [intl.relevantFinancialRatios('based-on-parent-statement')]:
    'Basert på regnskap for morselskap',
  [intl.relevantFinancialRatios('basic_earning_power')]: 'Grunnleggende inntjeningsevne',
  [intl.relevantFinancialRatios('capacity_ratio')]: 'Kapasitetsgrad',
  [intl.relevantFinancialRatios('cash_conversion_rate')]: 'Kontantkonvertering',
  [intl.relevantFinancialRatios('cash_ratio')]: 'Likviditetsgrad III',
  [intl.relevantFinancialRatios('current_assets_to_equity')]:
    'Omløpsmidler til egenkapital',
  [intl.relevantFinancialRatios('current_ratio')]: 'Likviditetsgrad',
  [intl.relevantFinancialRatios('debt_ratio')]: 'Gjeldsgrad',
  [intl.relevantFinancialRatios('debt_to_equity_ratio')]: 'Gjeldsgrad',
  [intl.relevantFinancialRatios('ebitda_to_debt_ratio')]: 'EBITDA til gjeldsgrad',
  [intl.relevantFinancialRatios('fixed_assets_to_long_term_liabilities_ratio')]:
    'Finansieringsgrad I',
  [intl.relevantFinancialRatios('gross_margin')]: 'Dekningsgrad',
  [intl.relevantFinancialRatios('interest_coverage')]: 'Rentedekningsgrad',
  [intl.relevantFinancialRatios('interest_coverage')]: 'Rentedekningsgrad',
  [intl.relevantFinancialRatios('interest_margin')]: 'Rentemargin',
  [intl.relevantFinancialRatios('interest_rate_on_debt_with_average')]: 'Gjeldsrente',
  [intl.relevantFinancialRatios('not-available')]: 'Ikke tilgjengelig',
  [intl.relevantFinancialRatios('one_year_change_in_equity')]:
    'Årlig endring i egenkapital',
  [intl.relevantFinancialRatios('operating_margin')]: 'Overskuddsgrad',
  [intl.relevantFinancialRatios('profit_margin')]: 'Resultatgrad',
  [intl.relevantFinancialRatios('return_on_assets')]: 'Totalkapitalens rentabilitet',
  [intl.relevantFinancialRatios('return_on_equity')]: 'Egenkapital rentabilitet',
  [intl.relevantFinancialRatios('solidity_ratio')]: 'Soliditetsgrad',
  [intl.relevantFinancialRatios('title')]: 'Relevante Finansielle Nøkkeltall',

  [intl.reportBug('agreement')]:
    'Ved å sende inn en rapport tillater vi oss å bruke e-posten din som referanse til dette problemet.',
  [intl.reportBug('description')]: 'Beskriv feilen',
  [intl.reportBug('drag-drop')]: '"Du kan dra og slippe',
  [intl.reportBug('error-in-credit-information')]: 'Feil i kredittinformasjon',
  [intl.reportBug('error-in-financial-data')]: 'Feil i finansielle data',
  [intl.reportBug('error-in-general-information')]: 'Feil i generell informasjon',
  [intl.reportBug('error-in-highlights')]: 'Feil i høydepunkter',
  [intl.reportBug('error-in-relation-data')]: 'Feil i relasjonsdata',
  [intl.reportBug('other')]: 'Annet',
  [intl.reportBug('report-bug-paper')]: 'Rapporter en feil',
  [intl.reportBug('send-button')]: 'Arkivere rapport',
  [intl.reportBug('type-of-report')]: 'Hva slags type feil oppstod?',
  [intl.restriction('basic-description')]:
    'Planen din dekker ikke denne funksjonen. Klikk på knappen under for mer informasjon.',
  [intl.restriction('create-new-list-content')]:
    'Denne funksjonen er ikke tilgjengelig for planen din',
  [intl.restriction('create-new-list-title')]: 'Overvåkingslister',
  [intl.restriction('credit-reports-button')]: 'Priser og planer',
  [intl.restriction('credit-reports-description')]:
    '"Du har ikke tilgang til denne funksjonen. Du kan oppgradere gjeldende plan eller kjøpe funksjonen ""Kredittrapport"" som et eget tillegg."',
  [intl.restriction('credit-reports-title')]: 'Kredittrapporter',
  [intl.restriction('export-button')]: 'Priser og planer',
  [intl.restriction('export-description')]:
    '"Du har ikke tilgang til denne funksjonen. Du kan oppgradere gjeldende plan eller kjøpe tillegget ""Eksport-funksjonalitet"" som en egen tilleggsfunksjon."',
  [intl.restriction('export-title')]: 'Eksport',
  [intl.restriction('key-figures-title')]: 'Nøkkeltall',
  [intl.restriction('risk-monitoring-button')]: 'Priser og planer',
  [intl.restriction('risk-monitoring-description')]:
    '"Du har ikke tilgang til denne funksjonen. Du kan oppgradere planen din eller kjøpe tillegget ""Risikoovervåking"" som en egen funksjon."',
  [intl.restriction('risk-monitoring-title')]: 'Risikoovervåking',
  [intl.riskMonitoring('intelligence-suite')]: 'Risk Insights',
  [intl.riskMonitoring('intelligence-suite-tooltip-access')]:
    'Få tilgang til porteføljeinnsikt og forstå risikoprofilen din.',
  [intl.riskMonitoring(
    'intelligence-suite-tooltip-no-access'
  )]: `Abonnementsplanen din inkluderer ikke Risk Insights. Ta imidlertid kontakt med Customer Success-teamet vårt hvis du vil lære mer om hvordan Risk Insights kan hjelpe deg med å ta informerte strategiske beslutninger.`,
  [intl.riskMonitoring('company')]: 'Selskap',
  [intl.riskMonitoring('company-search')]: 'Søk etter selskap',
  [intl.riskMonitoring('company-type')]: 'Selskapsform',
  [intl.riskMonitoring('companyName')]: 'Selskap',
  [intl.riskMonitoring('companyType')]: 'Selskapsform',
  [intl.riskMonitoring('country')]: 'Land',
  [intl.riskMonitoring('credit-max')]: 'Kredittgrenser',
  [intl.riskMonitoring('currency')]: 'Valuta',
  [intl.riskMonitoring('date-of-incorporation')]: 'Innlemmelsesdato',
  [intl.riskMonitoring('dateOfIncorporation')]: 'Innlemmelsesdato',
  [intl.riskMonitoring('edit-company-title')]: 'Rediger selskap',
  [intl.riskMonitoring('employee-interval')]: 'Medarbeiderintervall',
  [intl.riskMonitoring('employeeInterval')]: 'Medarbeiderintervall',
  [intl.riskMonitoring('equity')]: 'Egenkapital',
  [intl.riskMonitoring('from-to-count')]: '{from}-{to} av {count}',
  [intl.riskMonitoring('import-from-file')]: 'Importer fra fil',
  [intl.riskMonitoring('import-from-file-company-id-field')]: 'Velg felt med selskaps-ID',
  [intl.riskMonitoring('import-from-file-include-internal-id')]: 'Inkluder intern ID',
  [intl.riskMonitoring('import-from-file-internal-id-field')]:
    'Velg felt med din intern ID',
  [intl.riskMonitoring('import-from-manual-entry')]: 'Skriv inn manuelt',
  [intl.riskMonitoring('import-from-manual-entry-helper-text')]: '"Separer med mellomrom',
  [intl.riskMonitoring('internal-id')]: 'Intern ID',
  [intl.riskMonitoring('internalId')]: 'Intern ID',
  [intl.riskMonitoring('liquidity')]: 'Likviditet',
  [intl.riskMonitoring('local-id')]: 'Lokal ID',
  [intl.riskMonitoring('local-id-search')]: 'Finn med intern ID',
  [intl.riskMonitoring('internal-id-search')]: 'Finn med lokal ID',
  [intl.riskMonitoring('no-credit')]: 'Ingen kreditt',
  [intl.riskMonitoring('no-data')]: 'Ingen data tilgjengelig',
  [intl.riskMonitoring('profit-loss')]: 'Overskuddstap',
  [intl.riskMonitoring('profitLoss')]: 'Overskuddstap',
  [intl.riskMonitoring('reject-companies')]: 'Legg til liste i Kredittpolitikk',
  [intl.riskMonitoring('remaining-monitoring')]: 'Gjenværende overvåking',
  [intl.riskMonitoring('remove')]: 'Ta bort',
  [intl.riskMonitoring('risika-score')]: 'Score',
  [intl.riskMonitoring('rows-per-page')]: 'Per side',
  [intl.riskMonitoring('score')]: 'Score',
  [intl.riskMonitoring('solidity')]: 'Soliditet',
  [intl.riskMonitoring('status')]: 'Status',
  [intl.riskMonitoring('summation-change-duration')]: 'Endringer den siste uken',
  [intl.riskMonitoring('title')]: 'Risikoovervåking',
  [intl.riskMonitoring('top-negative')]: 'Topp negativ',
  [intl.riskMonitoring('you-already-have-preset-with-name')]:
    'Du har allerede et alternativ med dette navnet',
  [intl.riskMonitoring('please-select-a-name')]: 'Velg et navn',
  [intl.riskMonitoring('preset-name')]: 'Eksporter navn',
  [intl.riskMonitoring('select-preset')]: 'Velg eksport',
  [intl.riskMonitoring('no-presets-saved')]: 'Ingen eksport lagret',
  [intl.riskMonitoring('capital')]: 'Kapital',
  [intl.riskMonitoring('cannot-monitor-that-many-companies')]:
    'Abonnementsplanen din lar deg ikke overvåke så mange selskaper',
  [intl.riskMonitoring('invalid-request')]: 'Ugyldig forespørsel, vennligst sjekk listen',
  [intl.riskMonitoringNew('table-shown')]: 'Vist',
  [intl.riskMonitoringNew('table-hidden')]: 'Skjult',
  [intl.riskMonitoringNew('add')]: 'Legg til',
  [intl.riskMonitoringNew('add-companies-button')]: 'Import',
  [intl.riskMonitoringNew('add-companies-button')]: 'Importer',
  [intl.riskMonitoringNew('add-list-name-snackbar')]: 'Gi navn til listen!',
  [intl.riskMonitoringNew('add-name')]: 'Velg navn på listen',
  [intl.riskMonitoringNew('no-monitoring-list-available')]:
    'Ingen overvåkningsliste tilgjengelig',
  [intl.riskMonitoringNew('create-monitoring-list-text-empty')]:
    'Opprett eller bli med på en overvåkingsliste for å motta oppdateringer om selskaper som er viktige for deg. som er viktige for deg.',
  [intl.riskMonitoringNew('add-new-list')]: 'Ny liste',
  [intl.riskMonitoringNew('add-new-subscription')]: 'Legg til varsel',
  [intl.riskMonitoringNew('add-to-monitoring-counter')]:
    'Prøver å importere {count} selskaper til {listName}. Sikker på at du vil fortsette?',
  [intl.riskMonitoringNew('add-to-monitoring-list-title')]:
    'Last opp fil eller skriv inn selskaps-ID manuelt',
  [intl.riskMonitoringNew('added-some-companies')]:
    'Beløpet overstiger gjeldende overvåkingsplan. Kun noen selskaper er lagt til.',
  [intl.riskMonitoringNew('adding-companies-wait')]:
    'Legger til selskaper. Vennligst vent...',
  [intl.riskMonitoringNew('are-you-sure-delete')]: 'Sikker på at du vil slette {text}?',
  [intl.riskMonitoringNew('are-you-sure-delete-entries')]:
    '{count, plural, one {Sikker på at du vil slette selskap?} other {Sikker på at du vil slette {count} selskaper?}}',
  [intl.riskMonitoringNew('back')]: 'Tilbake',
  [intl.riskMonitoringNew('copy-button-text')]: 'Kopi',
  [intl.riskMonitoringNew('copy-to-another-list-title')]:
    'Velg listene du vil kopiere til',
  [intl.riskMonitoringNew('create-list-button')]: 'Lagre liste',
  [intl.riskMonitoringNew('create-new-list-text-field')]: 'Lag en ny liste',
  [intl.riskMonitoringNew('create-subscription')]: 'Opprett varsel',
  [intl.riskMonitoringNew('cvt-textfield-label')]: 'Eller angi selskaps-ID direkte her',
  [intl.riskMonitoringNew('delete')]: 'Slett',
  [intl.riskMonitoringNew('delete-companies-title')]:
    '{count, plural, one {Slett selskap} other {Slett {count} selskaper}}',
  [intl.riskMonitoringNew('delete-dialog-title')]: 'Slett en liste',
  [intl.riskMonitoringNew('delete-failed')]: 'Kunne ikke slette listen',
  [intl.riskMonitoringNew('delete-items')]: 'Slett',
  [intl.riskMonitoringNew('delete-list')]: 'Slett liste',
  [intl.riskMonitoringNew('delete-success')]: 'Listen ble slettet',
  [intl.riskMonitoringNew('deleting-companies-wait')]:
    'Sletter selskaper. Vennligst vent...',
  [intl.riskMonitoringNew('drag-drop')]:
    'Dra og slipp, eller <span class="filepond--label-action">velg en fil</span> (.csv eller .xlsx)',
  [intl.riskMonitoringNew('edit-button')]: 'Gi nytt navn',
  [intl.riskMonitoringNew('edit-list-button')]: 'Gi nytt navn til listen',
  [intl.riskMonitoringNew('edit-list-text-field')]: 'Gi nytt navn til listen',
  [intl.riskMonitoringNew('edit-name')]: 'Nytt navn',
  [intl.riskMonitoringNew('existing-subscriptions')]: 'Eksisterende varsler',
  [intl.riskMonitoringNew('export-companies-button')]: 'Eksport',
  [intl.riskMonitoringNew('failed-validation')]:
    '{amount} verdier mislyktes med validering og ble ikke lagt til listen!',
  [intl.riskMonitoringNew('field-list-header')]:
    'Velg et felt som inneholder selskaps-ID-er',
  [intl.riskMonitoringNew('handle-settings-button')]: 'Tabellinnstillinger',
  [intl.riskMonitoringNew('handle-visibility-button')]: 'Synlighet',
  [intl.riskMonitoringNew('items-copied')]:
    '{count, plural, one {Du har kopiert ett selskap.} other {Du har kopiert {count} selskaper.}}',
  [intl.riskMonitoringNew('items-deleted')]:
    '{count, plural, one {Du har slettet ett selskap.} other {Du har slettet {count} selskaper.}}',
  [intl.riskMonitoringNew('items-failed-to-copy')]: 'Kunne ikke kopiere selskaper.',
  [intl.riskMonitoringNew('items-failed-to-delete')]: 'Kunne ikke slette selskaper',
  [intl.riskMonitoringNew('items-failed-to-import')]: 'Kunne ikke importere selskaper.',
  [intl.riskMonitoringNew('items-failed-to-move')]: 'Kunne ikke flytte selskaper',
  [intl.riskMonitoringNew('items-imported')]: 'Selskaper importert.',
  [intl.riskMonitoringNew('items-moved')]:
    'Du har flyttet ett selskap / Du har flyttet {count} selskaper',
  [intl.riskMonitoringNew('items-moved')]:
    '{count, plural, one {You have successfully moved one company.} other {You have successfully moved {count} companies.}}',
  [intl.riskMonitoringNew('monitor-add-subscription')]: 'Legg til abonnement',
  [intl.riskMonitoringNew('monitor-settings')]: 'Angi preferanser',
  [intl.riskMonitoringNew('move-button-text')]: 'Flytt',
  [intl.riskMonitoringNew('move-to-another-list-title')]:
    'Velg listene du vil flytte til',
  [intl.riskMonitoringNew('new-list-added-failed')]: 'Kunne ikke legge til ny liste',
  [intl.riskMonitoringNew('new-list-added-success')]: 'Ny liste lagt til',
  [intl.riskMonitoringNew('new-list-private')]: 'Privat',
  [intl.riskMonitoringNew('new-list-public')]: 'Offentlig',
  [intl.riskMonitoringNew('new-list-subtitle')]: 'Velg brukere som kan se denne listen',
  [intl.riskMonitoringNew('no-credit')]: 'Ingen kreditt',
  [intl.riskMonitoringNew('no-subscriptions-text')]:
    'Det ser ut til at du ikke har noen lister du vil motta e-post for. Trykk på knappen over for å legge til en ny!',
  [intl.riskMonitoringNew('error-loading-page')]:
    'Det oppstod et problem med å laste inn overvåkningslistene dine. Oppdater siden og prøv igjen.',
  [intl.riskMonitoringNew('remove-internal-id')]: 'Fjern intern ID',
  [intl.riskMonitoringNew('remove-internal-id-description')]:
    'Du er i ferd med å fjerne {count} intern ID / Du er i ferd med å fjerne {count} interne IDer',
  [intl.riskMonitoringNew('remove-internal-id-title')]:
    'Fjern intern ID / Fjern interne IDer',
  [intl.riskMonitoringNew('save-changes')]: 'Lagre endringer',
  [intl.riskMonitoringNew('select-lists')]: 'Velg lister',
  [intl.riskMonitoringNew('select-subscription-lists')]:
    'Velg lister du vil motta oppdateringer på',
  [intl.riskMonitoringNew('select-subscription-options')]: 'Velg hva du vil høre om',
  [intl.riskMonitoringNew('selected')]:
    '{count, plural, =0 {Ingen valgt } other {{count} valgt}}',
  [intl.riskMonitoringNew('settings-header')]: 'Velg hvilke kolonner du vil se',
  [intl.riskMonitoringNew('submit')]: 'Send inn',
  [intl.riskMonitoringNew('subscribed-to-all')]:
    'Du abonnerer på alle tilgjengelige lister',
  [intl.riskMonitoringNew('subscription-add-failed')]:
    'Vi fikk problemer med å lage varselet',
  [intl.riskMonitoringNew('subscription-add-success')]: 'Varsling opprettet',
  [intl.riskMonitoringNew('subscription-delete-failed')]:
    'Vi hadde problemer med å slette varselet',
  [intl.riskMonitoringNew('subscription-delete-success')]: 'Varsling slettet',
  [intl.riskMonitoringNew('subscription-updated-success')]: 'Abonnementet ble oppdatert',
  [intl.riskMonitoringNew('table-actions-tooltip')]: 'Velg en rad først',
  [intl.riskMonitoringNew('text-field-hint')]: 'Tegn som komma',
  [intl.riskMonitoringNew('update')]: 'Oppdater',
  [intl.riskMonitoringNew('update-subscriptions')]: 'Dine varsler',
  [intl.riskMonitoringNew('update-subscriptions-title')]: 'Dine varsler',
  [intl.riskMonitoringNew('visibility-header')]: 'Oppdater hvem som kan se denne listen',
  [intl.riskMonitoringNew('your-lists')]: 'Dine lister',
  [intl.riskMonitoringNew('sum-of-all-ids-found')]:
    'Summen av alle ID-er som finnes i filen: {count}.',
  [intl.riskMonitoringNew('faulty-ids-found-message')]:
    '{count, plural, one {{count} defekt firma-ID funnet.} other {{count} defekt firma-IDs funnet.}}',
  [intl.riskMonitoringNew('faulty-ids-button-export-label')]: 'Eksporter feil ID',
  [intl.riskMonitoringNew('faulty-ids-file-name')]: 'FeilID',
  [intl.riskMonitoringNew('duplicate-ids-found-message')]:
    '{count, plural, one {{count} duplisere firma-ID funnet.} other {{count} duplisere firma-IDs funnet.}}',
  [intl.riskMonitoringNew('duplicate-ids-button-export-label')]: 'Eksporter duplisere ID',
  [intl.riskMonitoringNew('duplicate-ids-file-name')]: 'DuplisereID',
  [intl.riskMonitoringNew('language-choice')]: 'Velg ditt foretrukne språk',
  [intl.riskMonitoringNew('country-restriction-error')]:
    'Du kan kun overvåke selskaper fra Danmark, Sverige og Norge.',
  [intl.riskMonitoringNew('list-name-update-success')]: 'Navn på liste oppdatert',
  [intl.riskMonitoringNew('list-name-update-fail')]: 'Oppdatering mislyktes',

  [intl.riskMonitoringOverview('all')]: 'Alle',
  [intl.riskMonitoringOverview('da_DK')]: 'Danmark',
  [intl.riskMonitoringOverview('delete-all-button')]: 'Slett alt ',
  [intl.riskMonitoringOverview('delete-all-popup-text')]:
    'Denne handlingen vil slette alle selskapene du overvåker for øyeblikket. Sikker på at du vil fortsette?',
  [intl.riskMonitoringOverview('delete-all-popup-title')]:
    'Slett alle overvåkede selskaper',
  [intl.riskMonitoringOverview('high-risk')]: 'Høy',
  [intl.riskMonitoringOverview('low-risk')]: 'Lav',
  [intl.riskMonitoringOverview('medium-risk')]: 'Middels',
  [intl.riskMonitoringOverview('no-score')]: 'Ingen Score',
  [intl.riskMonitoringOverview('no_NO')]: 'Norge',
  [intl.riskMonitoringOverview('sv_SE')]: 'Sverige',
  [intl.riskMonitoringOverview('total')]: 'Totalt',

  [intl.riskMonitoringSubscription('add-description')]:
    'Du kan opprette et abonnement for parametrene du vil høre om. Vi sender deg en ukentlig post med endringene i overvåkingslisten din.',
  [intl.riskMonitoringSubscription('add-subscription')]: 'Legg til overvåking',
  [intl.riskMonitoringSubscription('add-subscription-title')]:
    'Legg til et overvåkingsabonnement',
  [intl.riskMonitoringSubscription('address')]: 'Adresse',
  [intl.riskMonitoringSubscription('all-important-changes')]: 'Alle viktige endringer',
  [intl.riskMonitoringSubscription('all-regular-changes')]: 'Alle regelmessige endringer',
  [intl.riskMonitoringSubscription('annual_reports')]: 'Årsrapporter',
  [intl.riskMonitoringSubscription('company_type')]: 'Selskapsform',
  [intl.riskMonitoringSubscription('email')]: 'E-post',
  [intl.riskMonitoringSubscription('policy_outcome')]: 'Utfall av kredittpolitikken',
  [intl.riskMonitoringSubscription('employees')]: 'Antall ansatte',
  [intl.riskMonitoringSubscription('gazette')]: 'Gazette',
  [intl.riskMonitoringSubscription('important-changes')]: 'Viktige endringer',
  [intl.riskMonitoringSubscription('industry')]: 'Industri',
  [intl.riskMonitoringSubscription('payment_remarks')]: 'Betalingsanmærkninger (Norge)',
  [intl.riskMonitoringSubscription('manage-description')]:
    'Du kan slette abonnementet eller endre parametrene du vil høre om i fremtiden.',
  [intl.riskMonitoringSubscription('manage-subscription')]: 'Preferanser',
  [intl.riskMonitoringSubscription('manage-subscription-title')]:
    'Administrer overvåkingsabonnementet',
  [intl.riskMonitoringSubscription('name')]: 'Selskapsnavn',
  [intl.riskMonitoringSubscription('phone_number')]: 'Telefonnummer',
  [intl.riskMonitoringSubscription('receive-updates-description')]:
    'Når det er endringer som er relevante for deg',
  [intl.riskMonitoringSubscription('receive-updates-title')]:
    'Oppdateringer til e-posten din',
  [intl.riskMonitoringSubscription('regular-changes')]: 'Regelmessige endringer',
  [intl.riskMonitoringSubscription('relations')]: 'Relasjoner',
  [intl.riskMonitoringSubscription('remove-subscription')]: 'Fjern abonnementet',
  [intl.riskMonitoringSubscription('score')]: 'Risika Score',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-added')]:
    'Vi kunne dessverre ikke legge til abonnementet',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-removed')]:
    'Vi kunne dessverre ikke fjerne abonnementet',
  [intl.riskMonitoringSubscription('snackbar-failed-subscription-updated')]:
    'Vi kunne dessverre ikke oppdatere abonnementet',
  [intl.riskMonitoringSubscription('snackbar-subscription-added')]:
    'Abonnementet er lagt til',
  [intl.riskMonitoringSubscription('snackbar-subscription-removed')]:
    'Abonnementet er fjernet',
  [intl.riskMonitoringSubscription('snackbar-subscription-updated')]:
    'Abonnementet er oppdatert',
  [intl.riskMonitoringSubscription('status')]: 'Selskapets Status',

  [intl.searchBusiness('api-error')]: 'Forespørsel feilet: {message}',
  [intl.searchBusiness('clear-range-picker')]: 'Fjern Risika Score',
  [intl.searchBusiness('company')]: 'Selskap',
  [intl.searchBusiness('employees')]: 'Ansatte',
  [intl.searchBusiness('local-id')]: 'Lokal ID',
  [intl.searchBusiness('max-export')]:
    '{count, plural, other {Eksporten er begrenset til de første {count} radene}}',
  [intl.searchBusiness('no-data')]: 'Ingen data tilgjengelig',
  [intl.searchBusiness('personal-id')]: 'Personlig ID',
  [intl.searchBusiness('results-time-elapsed')]: 'Resultat / Resultater',
  [intl.searchBusiness('risika-score')]: 'Risika Score',
  [intl.searchBusiness('search-field')]: 'Søk',
  [intl.searchBusiness('search-placeholder')]:
    'Skriv selskapsnavn, id, adresse, e-post eller telefonnummer (minst 3 tegn)',
  [intl.searchBusiness('selector-companies')]:
    '{count, plural, one {Selskap ({countDisplay})} other {Selskaper ({countDisplay})}}',
  [intl.searchBusiness('selector-no-companies')]:
    'Vi fant ingen selskaper. Søker etter personer i stedet.',
  [intl.searchBusiness('selector-no-people')]:
    'Vi fant ingen mennesker. Søker etter personbedrifter.',
  [intl.searchBusiness('selector-people')]:
    '{count, plural, one {Enkeltperson (#)} other {Enkeltpersoner (#)}}',
  [intl.creditPolicyNew('people')]: 'Enkeltpersoner',
  [intl.searchBusiness('status')]: 'Status',
  [intl.searchBusiness('title')]: 'Søk',
  [intl.searchBusiness('type')]: 'Type',

  [intl.settings('address')]: 'Selskapets adresse',
  [intl.settings('all-users')]: 'Alle brukere',
  [intl.settings('are-you-sure-delete-user')]:
    'Sikker på at du vil slette denne brukeren?',
  [intl.settings('cant-delete-yourself')]: 'Du kan ikke slette deg selv!',
  [intl.settings('cant-have-user-and-admin')]:
    '"Du kan enten velge ""Bruker"" eller ""Administrator""!"',
  [intl.settings('code-helper-text')]:
    'Kopier denne teksten og legg den til nettstedet ditt',
  [intl.settings('company-details-title')]: 'Detaljer om abonnementet ditt',
  [intl.settings('company-information-title')]: 'Selskapsinformasjon',
  [intl.settings('company-information-updated-success')]:
    'Informasjon om selskapet er oppdatert',
  [intl.settings('confirm-new-password')]: 'Bekreft passord',
  [intl.settings('confirm-password-requirements')]:
    'Bekreftet passord må være det samme som passordet',
  [intl.settings('content-copied')]: 'Innhold kopiert til utklippstavlen',
  [intl.settings('copy-icon')]: 'Kopier innhold',
  [intl.settings('customerType')]: 'Abonnementstype',
  [intl.settings('customerType')]: 'Subscription type',
  [intl.settings('dark')]: 'Mørk',
  [intl.settings('domain-label')]: 'Domene',
  [intl.settings('email-validation')]: 'Ugyldig e-postadresse',
  [intl.settings('phone-validation')]: 'Ugyldig telefonnummer',
  [intl.settings('name-validation')]: 'Vennligst legg til brukernavn',
  [intl.settings('email-validation')]: 'Ugyldig e-postadresse',
  [intl.settings('general-information')]: 'Generell informasjon',
  [intl.settings('generate')]: 'Generer',
  [intl.settings('invalid-phone')]: 'Ugyldig telefonnummer',
  [intl.settings('invalid-url')]: 'Legg til en gyldig URL',
  [intl.settings('invalid-url-phone')]: 'Ugyldig URL og telefonnummer',
  [intl.settings('large')]: 'Stor',
  [intl.settings('last-login')]: 'Siste aktivitet',
  [intl.settings('light')]: 'Lys',
  [intl.settings('link-type')]: 'Koblingstype',
  [intl.settings('logo-title')]: 'Steg 1: Fortell oss hvor du vil bruke logoen vår',
  [intl.settings('mail-send')]: 'E-post sendt',
  [intl.settings('medium')]: 'Medium',
  [intl.settings('monitoring-used')]: 'Antall overvåkinger',
  [intl.settings('name')]: 'Selskapsnavn',
  [intl.settings('name-validation')]: 'Legg til fornavn',
  [intl.settings('new-password')]: 'Nytt passord',
  [intl.settings('password-requirements')]: 'Passordet må være minst 8 tegn langt',
  [intl.settings('password-title')]: 'Passord',
  [intl.settings('password-updated-successfully')]: 'Passordet ble oppdatert',

  [intl.settings('add-user-button')]: 'Legg til bruker',
  [intl.settings('user-create-success')]: 'Bruker er opprettet',
  [intl.settings('user-create-error')]: 'Opprettelse av bruker mislyktes',
  [intl.settings('user-update-success')]: 'Bruker oppdatert',
  [intl.settings('user-update-error')]: 'Kunne ikke oppdatere brukeren',
  [intl.settings('user-delete-success')]: 'Bruker slettet',
  [intl.settings('user-delete-error')]: 'Kunne ikke slette bruker',
  [intl.settings('user-table-results-per-page')]: 'Resultater per side',

  [intl.settings('phone-label')]: 'Telefonnummer',
  [intl.settings('preview-title')]: 'Steg 2: Design logoen ',
  [intl.settings('radio-text')]: 'Hvilken bakgrunn skal logoen være på?',
  [intl.settings('remainingMonitoring')]: 'Gjenværende overvåking',
  [intl.settings('remainingUsers')]: 'Gjenværende brukere',
  [intl.settings('risika-logo')]: 'Få Risika-logo',
  [intl.settings('see-code')]: 'Se kode',
  [intl.settings('select-helper-text')]: 'Trenger du HTML eller direkte koblingskode?',
  [intl.settings('share-logo')]: 'Del logo',
  [intl.settings('share-logo-message')]:
    'Legg til en tilpasset melding som sendes med logoen',
  [intl.settings('size-text')]: 'Størrelsen på logoen ',
  [intl.settings('small')]: 'Liten',
  [intl.settings('step-3')]: 'Steg 3: Send koden til utvikleren din',
  [intl.settings('termsOfService')]: 'Vilkår og betingelser',
  [intl.settings('title')]: 'Innstillinger',
  [intl.settings('update-avatar')]: 'OPPDATER AVATAR',
  [intl.settings('update-company')]: 'Oppdater selskap',
  [intl.settings('update-password')]: 'Oppdater passord',
  [intl.settings('update-user')]: 'Oppdater bruker',
  [intl.settings('user-information-title')]: 'Brukerinformasjon',
  [intl.settings('user-information-title')]: 'User information',
  [intl.settings('user-management')]: 'Brukeradministrasjon',
  [intl.settings('users-used')]: 'Antall brukere',
  [intl.settings('users-used')]: 'Number of users',
  [intl.settings('whitelist')]: 'Nettside',
  [intl.settings('whitelist-helper-text')]: '"Etter å ha kommet inn på nettsiden',
  [intl.settings('scoring-model')]: 'Poengmodell',
  [intl.settings('click-to-change-currency')]: 'Klikk for at endre valuta',
  [intl.settings('credit-override')]: 'Kredittoverstyring',

  [intl.settings('score-model-dk')]: 'Poengmodell (Danmark)',
  [intl.settings('score-model-se')]: 'Poengmodell (Sverige)',
  [intl.settings('score-model-no')]: 'Poengmodell (Norge)',
  [intl.settings('score-model-uk')]: 'Poengmodell (STORBRITANNIA)',
  [intl.settings('score-model-fi')]: 'Poengmodell (Finland)',
  [intl.settings('user-information-updated')]: 'Brukerinformasjonen er oppdatert',
  [intl.settings('user-password-updated')]: 'Passordet er oppdatert',

  [intl.shareReport('add-custom-message')]: 'Beskjed til mottaker (valgfritt)',
  [intl.shareReport('select-recipient')]: 'Velg mottaker',
  [intl.shareReport('sender-name')]: 'Navn på avsender (valgfritt)',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from NO'
  )]: 'Abonnementsplanen din lar deg ikke overvåke ressurser fra Norge',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor resources from SE'
  )]: 'Abonnementsplanen din lar deg ikke overvåke ressurser fra Sverige',
  [intl.snackbar(
    'Your subscription plan does not allow you to monitor this many companies'
  )]: 'Abonnementsplanen din lar deg ikke overvåke så mange selskaper',
  [intl.snackbar('account-activated-failed')]: 'En feil oppstod',
  [intl.snackbar('account-activated-success')]:
    'Perfekt! Nå kan du begynne å bruke Risika',
  [intl.snackbar('add-companies-monitoring')]: 'Selskaper lagt til Risikoovervåking',
  [intl.snackbar('add-companies-monitoring-failed')]:
    '"Import mislyktes: Kontroller at du har en kolonne som heter ""Organisasjonsnummer"""',
  [intl.snackbar('add-company-monitoring')]: 'Selskap lagt til Risikoovervåking',
  [intl.snackbar('added-back')]: 'lagt tilbake til risikoovervåking',
  [intl.snackbar('all-added-back')]: 'selskaper lagt tilbake til risikoovervåking',
  [intl.snackbar('companies-added-to-list')]: 'Selskaper lagt til {list}',
  [intl.snackbar('companies-added')]: 'Bedrifter lagt til',
  [intl.snackbar('companies-failed-to-add')]:
    'Selskaper som ikke gikk å legge til {list}',
  [intl.snackbar('company-added-to-list')]: 'Selskapet lagt til i {list}',
  [intl.snackbar('company-removed-from-list')]: 'Selskap fjernet fra {list}',
  [intl.snackbar('copied')]: 'Kopiert',
  [intl.snackbar('dismiss')]: 'Avvis',
  [intl.snackbar('export-failed')]: 'Kunne ikke eksportere',
  [intl.snackbar('export-success')]: 'Eksporten er klar',
  [intl.snackbar('failed-to-add-company')]: 'Kunne ikke legge til selskapet',
  [intl.snackbar('failed-to-add-emails')]: 'Kunne ikke legge til e-post',
  [intl.snackbar('fields-required')]: 'Fyll ut de obligatoriske feltene',
  [intl.snackbar('invalid-email')]: 'Legg til en gyldig e-post',
  [intl.snackbar('logo-shared-succes')]: 'Logoen ble delt',
  [intl.snackbar('maximum-export-exceded-exporting-less')]:
    'Maksimalt antall eksporteringer av selskaper er overskredet. Eksporterer de første {eksport Limit} selskapene',
  [intl.snackbar('no-subsidiaries')]: 'Selskapet har ingen datterselskaper',
  [intl.snackbar('password-not-allowed')]: 'Velg et annet passord',
  [intl.snackbar('remove-companies-monitoring')]:
    'Alle selskaper som er fjernet fra Risikoovervåking',
  [intl.snackbar('remove-company-monitoring')]: 'Selskap fjernet fra Risikoovervåking',
  [intl.snackbar('remove-failed')]: 'Vi kunne ikke fjerne webhooken din',
  [intl.snackbar('remove-successfull')]: 'Webhooken din ble fjernet',
  [intl.snackbar('report-error-something-wrong')]: 'Noe gikk galt',
  [intl.snackbar('report-error-no-data-available')]: 'Data utilgjengelig',
  [intl.errors('observation-list-no-data-to-load')]:
    'Beklager, vi har for øyeblikket ikke de nødvendige dataene for å vise denne grafikken.',
  [intl.snackbar('report-error-timeout')]: 'For øyeblikket har vi ikke rapport for',
  [intl.snackbar('report-failed-to-submit')]: 'Rapporten kunne ikke sendes',
  [intl.snackbar('report-submited')]: 'Rapporten ble sendt',
  [intl.snackbar('reset-filters')]: 'Filtrene er tilbakestilt',
  [intl.snackbar('settings-saved')]: 'Innstillinger lagret',
  [intl.snackbar('settings-saved')]: 'Settings saved',
  [intl.snackbar('shared-succes')]: 'Rapporten ble delt',
  [intl.snackbar('test-failed')]: 'Noe gikk galt med testen: {error}',
  [intl.observationalLists('press-enter-email')]:
    'Trykk på Enter når du har skrevet inn e-posten din for å bekrefte.',
  [intl.snackbar('test-successfull')]:
    'Du vil motta et eksempel fra oss innen to minutter',
  [intl.snackbar('undo')]: 'Angre',
  [intl.snackbar('users-added-successfully')]: 'Brukere ble lagt til',
  [intl.snackbar('warn-user-of-new-version')]:
    'Vi har nettopp lansert en dansk versjon av plattformen. Velg språk øverst til høyre for å løse feil tekst på plattformen.',

  [intl.table('loading')]: 'Laster...',
  [intl.table('next')]: 'Neste',
  [intl.table('no-rows')]: 'Ingen rader tilgjengelig',
  [intl.table('of')]: 'med',
  [intl.table('page')]: 'Side',
  [intl.table('previous')]: 'Forrige',
  [intl.table('rows')]: 'rader',

  [intl.toolbar('add-email')]: 'Legg til e-post',
  [intl.toolbar('add-phone')]: 'Legg til telefonnummer',
  [intl.toolbar('billing-plan')]: 'Faktureringsplan',
  [intl.toolbar('edit-profile')]: 'Rediger profil',
  [intl.toolbar('greeting')]: 'Hei',
  [intl.toolbar('logout')]: 'Logg ut',
  [intl.toolbar('role')]: 'Rolle',
  [intl.toolbar('welcome-to-risika')]: 'Velkommen til Risika kontroll',

  [intl.tooltip('click-to-copy')]: 'Klikk for å kopiere',

  [intl.userManagement('delete-dialog-title')]: 'Slett bruker',
  [intl.userManagement('delete-dialog-content')]:
    'Ved å slette denne brukeren samtykker du i å permanent tilbakekalle vedkommendes tilgang til alle Risika-produkter og API. Er du sikker på at du vil fortsette?',
  [intl.userManagement('failed-to-load-users')]: 'Kunne ikke laste inn brukere',
  [intl.userManagement('invalid-email')]: 'Ugyldig epost',
  [intl.userManagement('invite-header')]: 'Inviter brukere til Risika',
  [intl.userManagement('invite-users-button')]: 'Inviter brukere',
  [intl.userManagement('invites-send-failed')]: 'Vi kunne ikke sende e-post til {email}',
  [intl.userManagement('invites-send-success')]: 'Invitasjon til {email} er sendt',
  [intl.userManagement('maximum-invites-reached')]:
    'Du har nådd grensen for invitasjoner. Klikk her for å kjøpe flere',
  [intl.userManagement('more-than-30')]: 'Mer enn 30 dager',
  [intl.userManagement('no-data-yet')]: 'Ingen data ennå',
  [intl.userManagement('role-change-failed')]: 'Kunne ikke endre rolle',
  [intl.userManagement('role-change-success')]: 'Rolle ble endret',
  [intl.userManagement('send-invite-button')]: 'Inviter',
  [intl.userManagement('user-delete-failed')]: 'Kunne ikke slette bruker',
  [intl.userManagement('user-delete-success')]: 'Slettet bruker',

  [intl.visualScore('high-risk')]: 'Høy risiko',
  [intl.visualScore('low-risk')]: 'Lav risiko',
  [intl.visualScore('medium-risk')]: 'Middels risiko',
  [intl.companyComparison('title')]: 'Sammenligne',
  [intl.companyComparison('add-to-comparison-button')]: 'Legg til sammenligning',
  [intl.companyComparison('add-to-comparison-tooltip')]:
    'Legg dette selskapet til komparativ liste',
  [intl.companyComparison('dialog-cannot-add-title')]: 'Selskapet kunne ikke legge til',
  [intl.companyComparison('dialog-cannot-add-content')]:
    'Selskapet kunne ikke legges til sammenligningslisten.',
  [intl.observationalLists('title')]: 'Risk Insights',
  [intl.observationalLists('risika-score-title')]: 'Risika Kreditscore',
  [intl.observationalLists('risika-score-by-time-title')]:
    'Utvikling i Risika Kredittscore',
  [intl.observationalLists('risika-score-by-company-title')]:
    'Risika Kredittscore Etter Selskapsform',
  [intl.observationalLists('risika-score-distribution-title')]:
    'Risiko Kredittscorefordeling',
  [intl.observationalLists('risika-score-employees-title')]:
    'Risiko Kredittscore Etter Antall Ansatte',
  [intl.observationalLists('risika-score-industry-title')]:
    'Risiko Kredittscore Etter Bransje',
  [intl.observationalLists('key-figures-industry-title')]: 'Nøkkeltall Etter Bransje',
  [intl.observationalLists('geographical-risika-score-title')]:
    'Risika Kredittscore Fordelt Geografisk',
  [intl.observationalLists('development-selected-key-figures-title')]:
    'Utvikling i Utvalgte Nøkkeltall',
  [intl.observationalLists('risika-score-over-time-title')]:
    'Utvikling i Risiko Kredittscore',
  [intl.observationalLists('risika-key-figures-by-company-type-title')]:
    'Nøkkeltall Etter Selskapsform',
  [intl.observationalLists('tab-risika-credit-score')]: 'Risika Kreditscore',
  [intl.observationalLists('tab-financial-performance')]: 'Økonomisk Ytelse',
  [intl.observationalLists('tab-companies')]: 'Bedrifter',
  [intl.observationalLists('annual-result-average-title')]: 'Årsresultat, Gj.sn.',
  [intl.observationalLists('equity-average-title')]: 'Egenkapital, Gj.sn.',
  [intl.observationalLists('employees-average-title')]: 'Antall ansatte, Gj.sn.',
  [intl.observationalLists('development-credit-policy-title')]:
    'Utvikling i Overholdelse Av Kredittpolitikken',
  [intl.observationalLists('list-id-error')]: 'Oppgi gyldig liste-ID!',
  [intl.observationalLists('credit-score')]: 'Kredittscore',
  [intl.observationalLists('select-industry')]: 'Velg bransje',
  [intl.observationalLists('highlights')]: 'Oppmerksomhetspunkter',
  [intl.observationalLists('credit_policy.violations')]: 'Brudd',
  [intl.observationalLists('risk_assessment.code')]: 'Risikonivå',
  [intl.observationalLists('credit_automator_result')]: 'Utfall av kredittpolitikken',
  [intl.observationalLists('credit_policy.allowed')]: 'Samsvar',
  [intl.observationalLists('company-per-page')]: 'Bedrift per side',
  [intl.observationalLists('export-button-tooltip')]:
    'Velg de virksomheter du vil eksportere.',
  [intl.autoSelectCompanies('search-box-title')]: 'Søk etter selskaper',
  [intl.keyFigures('title')]: 'Nøkkeltall',
  [intl.keyFiguresDevelopment('title')]: 'Financial Ratios Development',
  [intl.keyFiguresDevelopment('liquidity-ratio')]: 'Likviditetsgrad',
  [intl.keyFiguresDevelopment('result')]: 'Resultat',
  [intl.charts('radar-tooltip-score-since')]: 'Siden',

  [intl.charts('radar-tooltip-distress')]: 'Distress: {value}%',
  [intl.charts('radar-tooltip-current-ratio')]: 'Løpende forholdstall: {value}',
  [intl.charts('radar-tooltip-explanation')]: 'Dette anses som {rating} i sin bransje',

  [intl.charts('radar-tooltip-ratio')]:
    '{key}et er {value}%, som regnes som et {rating} {key} i industrien.',
  [intl.charts('radar-key-figures-info')]:
    'Diagrammet viser hvordan selskapets nøkkeltall ser ut sammenlignet med andre selskaper i samme bransje. De fire nøkkeltallene representerer selskapets lønnsomhet, soliditet, likviditet og konkursrisiko.',
  [intl.alikeCompanies('dialog-not-found-title')]: 'Ingen firma å vise',
  [intl.alikeCompanies('dialog-not-found-content')]: 'Ingen lignende firma ble funnet.',
  [intl.alikeCompanies('tooltip')]:
    'Et lignende selskap vil bli funnet fra det første selskapet som er i sammenligningen. Det lignende selskapet er basert på aktive selskaper som er i samme bransje og som har omtrent like mange ansatte og kapital.',
  [intl.portfolioInsights('drawer-trigger-button')]: 'Porteføljeinnsikt',
  [intl.portfolioInsights('tab-summary-label')]: 'Sammendrag',
  [intl.portfolioInsights('tab-compliance-label')]: 'Samsvar',
  [intl.portfolioInsights('tab-scheduler-label')]: 'Planlegger',
  [intl.portfolioInsights('tab-scheduler-destination')]: 'Mottaker',
  [intl.portfolioInsights('tab-scheduler-destination-email')]: 'E-post',
  [intl.portfolioInsights('tab-scheduler-destination-email-recipients')]:
    'E-postmottaker',
  [intl.portfolioInsights('tab-scheduler-emails-list-placeholder')]:
    'Spesifiser minst én mottaker',
  [intl.portfolioInsights('tab-scheduler-emails-list-invalid-email-warning')]:
    'Ugyldig epost. Sørg for at alle e-postadresser er riktige.',
  [intl.portfolioInsights('tab-scheduler-frequency-label')]: 'Frekvens',
  [intl.portfolioInsights('tab-scheduler-frequency-send-every')]: 'Send hvert',
  [intl.portfolioInsights('tab-scheduler-frequency-week')]: 'Uke',
  [intl.portfolioInsights('tab-scheduler-frequency-month')]: 'Måned',
  [intl.portfolioInsights('tab-scheduler-frequency-year')]: 'År',
  [intl.portfolioInsights('tab-scheduler-month-first-day')]: 'First day of month',
  [intl.portfolioInsights('tab-scheduler-month-first-week-day')]:
    'First week day of month',
  [intl.portfolioInsights('tab-scheduler-month-last-day')]: 'Last day of month',
  [intl.portfolioInsights('tab-scheduler-month-last-week-day')]: 'Last week day of month',
  [intl.portfolioInsights('tab-scheduler-frequency-send-on')]: 'Dag',
  [intl.portfolioInsights('tab-scheduler-frequency-starting-from')]: 'Starter fra',
  [intl.portfolioInsights('tab-scheduler-frequency-day-mon')]: 'Man',
  [intl.portfolioInsights('tab-scheduler-frequency-day-tue')]: 'Tir',
  [intl.portfolioInsights('tab-scheduler-frequency-day-wed')]: 'Ons',
  [intl.portfolioInsights('tab-scheduler-frequency-day-thu')]: 'Tor',
  [intl.portfolioInsights('tab-scheduler-frequency-day-fri')]: 'Fre',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sat')]: 'Lør',
  [intl.portfolioInsights('tab-scheduler-frequency-day-sun')]: 'Søn',
  [intl.portfolioInsights('tab-scheduler-status')]: 'Status',
  [intl.portfolioInsights('tab-scheduler-status-active')]: 'Aktiv',
  [intl.portfolioInsights('tab-scheduler-status-disabled')]: 'Deaktivert',
  [intl.portfolioInsights('tab-scheduler-save-changes')]: 'Lagre endringer',
  [intl.portfolioInsights('tab-scheduler-subscription-create-successful')]:
    'Abonnementet er opprettet',
  [intl.portfolioInsights('tab-scheduler-subscription-update-successful')]:
    'Abonnementet ble oppdatert',
  [intl.portfolioInsights('tab-scheduler-subscription-create-fail')]:
    'Kunne ikke opprette abonnement',
  [intl.portfolioInsights('tab-scheduler-subscription-update-fail')]:
    'Kunne ikke oppdatere abonnementet',
  [intl.portfolioInsights('portfolio-score')]: 'PORTEFØLJEPOENG',
  [intl.portfolioInsights('points-of-attention')]: 'Opmærksomhedspunkter',
  [intl.portfolioInsights('portfolio-risk-distribution')]:
    'Risikofordeling i Porteføljen',
  [intl.portfolioInsights('portfolio-risk-distribution-subtitle')]:
    '{currentCompaniesCount} av {allCompaniesCount} selskaper',
  [intl.portfolioInsights('Low risk')]: 'Lav risiko',
  [intl.portfolioInsights('Medium risk')]: 'Middels risiko',
  [intl.portfolioInsights('High risk')]: 'Høy risiko',
  [intl.portfolioInsights('Unknown risk')]: 'Ukjent risiko',
  [intl.portfolioInsights('portfolio-risk-history')]: 'Porteføljerisikohistorie',
  [intl.portfolioInsights('credit-policy-compliance')]: 'Overholdelse Av Kredittpolicy',
  [intl.portfolioInsights('credit-policy-violation')]: 'Brudd På Kredittpolicyen',
  [intl.portfolioInsights('violations')]: 'Brudd',
  [intl.portfolioInsights('compliance-history')]: 'Overholdelseshistorikk',
  [intl.portfolioInsights('compliant-label')]: 'Samsvarlig',
  [intl.portfolioInsights('non-compliant-label')]: 'Ikke-kompatibel',
  [intl.portfolioInsights('approved')]: 'Kreditt godkjent',
  [intl.portfolioInsights('rejected')]: 'Kreditt avslått',
  [intl.portfolioInsights('manual-check')]: 'Manuell kontroll',
  [intl.portfolioInsights('no-policy-set')]: 'Ingen policy anvendt',
  [intl.portfolioInsights('allowed-label')]: 'Godkjent',
  [intl.portfolioInsights('manual_check-label')]: 'Manuell kontroll',
  [intl.portfolioInsights('rejected-label')]: 'Avvist',
  [intl.portfolioInsights('do_not_evaluate-label')]: 'Ingen retningslinjer',
  /*
  [intl.portfolioInsights('company-too-young')]: 'Selskapet er for ungt',
  [intl.portfolioInsights('auditor-not-registered')]: 'Revisor er ikke registrert',
  [intl.portfolioInsights('too-many-bankruptcies')]: 'For mange konkurser',
  [intl.portfolioInsights('negative-equity')]: 'Negativ egenkapital',
  */
  [intl.errors('hubspot_error')]:
    'E-postvalidering mislyktes. Bruk en bedrifts-e-post eller kontakt vår kundeservice',
  [intl.errors('companies_local_id_country_unique')]:
    'Organisasjonsnummer er allerede i bruk',
  [intl.errors('company_already_exists')]: 'Organisasjonsnummer er allerede i bruk',
  [intl.errors('invalid_local_id')]: 'Du har brukt et ugyldig organisasjonsnummer',
  [intl.errors('error_creating_new_customer')]:
    'Noe gikk galt. Vennligst kontakt vår kundeservice på contact@risika.com',
  [intl.portfolioInsights('compliant-label')]: 'Samsvarlig',
  [intl.portfolioInsights('avg.')]: 'gj.sn.',
  [intl.errors('observation-list-can-t-be-loaded')]:
    'Beklager, vi kan ikke laste inn dataene for denne grafikken for øyeblikket, vennligst prøv igjen senere.',
  [intl.errors('service-unavailable')]: 'Tjeneste Utilgjengelig',
  [intl.errors('service-unavailable-description')]:
    'Vi kunne ikke hente detaljene akkurat nå. Vennligst prøv igjen senere.',
  [intl.errors('contract-min-date-error')]:
    'Kontraktens sluttdato kan ikke være tidligere enn startdatoen.',
}

export default messages
